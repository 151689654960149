export enum ClientEnum {
  CLIENT_BRONZE = 'CLIENT_BRONZE',
  CLIENT_ARGENT = 'CLIENT_ARGENT',
  CLIENT_OR = 'CLIENT_OR',
  SORT_CLIENT_BRONZE = 'SORT_3_CLIENT_BRONZE',
  SORT_CLIENT_ARGENT = 'SORT_2_CLIENT_ARGENT',
  SORT_CLIENT_OR = 'SORT_1_CLIENT_OR',
}

// Le tri classe les médailles par ordre alphabétique, On mappe nos constantes avec d'autres constantes triés
export function mappingClientSegmentationForListSort(clientSegmentation: string): string {
  if (clientSegmentation === ClientEnum.CLIENT_BRONZE) {
    return ClientEnum.SORT_CLIENT_BRONZE;
  }
  if (clientSegmentation === ClientEnum.CLIENT_ARGENT) {
    return ClientEnum.SORT_CLIENT_ARGENT;
  }
  if (clientSegmentation === ClientEnum.CLIENT_OR) {
    return ClientEnum.SORT_CLIENT_OR;
  }
}
