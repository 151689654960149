import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MenuComponent } from './menu/menu.component';
import { IcKitComponent } from './icon/ic-kit/ic-kit.component';
import { IcMedArgComponent } from './icon/ic-med-arg/ic-med-arg.component';
import { IcMedOrComponent } from './icon/ic-med-or/ic-med-or.component';
import { IcMedBronzeComponent } from './icon/ic-med-bronze/ic-med-bronze.component';
import { IcPieceFabComponent } from './icon/ic-piece-fab/ic-piece-fab.component';
import { IcPieceAcheteeComponent } from './icon/ic-piece-achetee/ic-piece-achetee.component';
import { IcSousEnsembleComponent } from './icon/ic-sous-ensemble/ic-sous-ensemble.component';
import { IcPieceFantomeComponent } from './icon/ic-piece-fantome/ic-piece-fantome.component';
import { IconSdcccComponent } from './icon/icon-sdccc/icon-sdccc.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { Select2Component } from './select2/select2.component';

import { SharedModule } from '../shared/shared.module';
import {ToastComponent} from './toast/toast.component';
import { ListComponent } from './list/list.component';
import { ListCellComponent } from './list-cell/list-cell.component';
import {TreeTableModule} from 'primeng/treetable';
import {TreeModule} from 'primeng/tree';

import { BadgeComponent } from './icon/badge/badge.component';
import {DropdownModule} from 'primeng/dropdown';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MultiselectComponent } from './multiselect/multiselect.component';
import {MultiSelectModule} from 'primeng/multiselect';
import { BlocFilterComponent } from './bloc-filter/bloc-filter.component';
import { InputComponent } from './input/input.component';
import { FieldErrorComponent } from './field-error/field-error.component';
import {InputTextModule} from 'primeng/inputtext';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { ModalComponent } from './modal/modal.component';
import {TooltipModule} from 'primeng/tooltip';
import { BlocGlobalIndicatorComponent } from './bloc-global-indicator/bloc-global-indicator.component';
import {ScrollTopModule} from 'primeng/scrolltop';
import { IconValidationStatusComponent } from './icon/icon-validation-status/icon-validation-status.component';
import { IconRealisationStatusComponent } from './icon/icon-realisation-status/icon-realisation-status.component';
import {CalendarModule} from 'primeng/calendar';
import {CalendarComponent} from './calendar/calendar.component';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {BlocDashboardFilterComponent} from './bloc-dashboard-filter/bloc-dashboard-filter.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { InputSwitchComponent } from './input-switch/input-switch.component';
import {SliderModule} from 'primeng/slider';
import { IcIncotermVendeurComponent } from './icon/ic-incoterm-vendeur/ic-incoterm-vendeur.component';
import { IcIncotermTruckComponent } from './icon/ic-incoterm-truck/ic-incoterm-truck.component';
import { IcIncotermExportCustomsComponent } from './icon/ic-incoterm-export-customs/ic-incoterm-export-customs.component';
import { IcIncotermChargementComponent } from './icon/ic-incoterm-chargement/ic-incoterm-chargement.component';
import { IcIncotermMainComponent } from './icon/ic-incoterm-main/ic-incoterm-main.component';
import { IcIncotermDechargementComponent } from './icon/ic-incoterm-dechargement/ic-incoterm-dechargement.component';
import { IcIncotermImportCustomsComponent } from './icon/ic-incoterm-import-customs/ic-incoterm-import-customs.component';
import { IcIncotermBuyerComponent } from './icon/ic-incoterm-buyer/ic-incoterm-buyer.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import {ToolbarModule} from 'primeng/toolbar';
import {TabMenuModule} from 'primeng/tabmenu';
import {TabViewModule} from 'primeng/tabview';
import { DicoComponent } from './dico/dico.component';
import { BacklogComponent } from './backlog/backlog.component';
import { VersionComponent } from './version/version.component';
import {AccordionModule} from 'primeng/accordion';
import { DocComponent } from './doc/doc.component';
import { StructureComponent } from './structure/structure.component';
import { TagComponent } from './tag/tag.component';
import {InputNumberModule} from 'primeng/inputnumber';
import {StructureDicoComponent} from '@app/ui/structureDico/structure.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    IcKitComponent,
    IcMedArgComponent,
    IcMedOrComponent,
    IcMedBronzeComponent,
    IcPieceFabComponent,
    IcPieceAcheteeComponent,
    IcSousEnsembleComponent,
    IcPieceFantomeComponent,
    IconSdcccComponent,
    BadgeComponent,
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    ListComponent,
    ToastComponent,
    ListCellComponent,
    DropdownComponent,
    Select2Component,
    MultiselectComponent,
    BlocFilterComponent,
    InputComponent,
    ModalComponent,
    FieldErrorComponent,
    BlocGlobalIndicatorComponent,
    IconValidationStatusComponent,
    IconRealisationStatusComponent,
    CalendarComponent,
    BlocDashboardFilterComponent,
    CheckboxComponent,
    InputSwitchComponent,
    IcIncotermVendeurComponent,
    IcIncotermTruckComponent,
    IcIncotermExportCustomsComponent,
    IcIncotermChargementComponent,
    IcIncotermMainComponent,
    IcIncotermDechargementComponent,
    IcIncotermImportCustomsComponent,
    IcIncotermBuyerComponent,
    DicoComponent,
    BacklogComponent,
    VersionComponent,
    DocComponent,
    StructureComponent,
    StructureDicoComponent,
    TagComponent,
  ],
    imports: [
        CommonModule,
        SharedModule,
        DropdownModule,
        FormsModule,
        MultiSelectModule,
        ReactiveFormsModule,
        InputTextModule,
        TooltipModule,
        ProgressSpinnerModule,
        CalendarModule,
        InputTextareaModule,
        SliderModule,
        ConfirmDialogModule,
        ToolbarModule,
        TabMenuModule,
        TabViewModule,
        AccordionModule,
        InputNumberModule
    ],
  exports: [
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    ListComponent,
    ToastComponent,
    TreeTableModule,
    TreeModule,
    ListCellComponent,
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    IconSdcccComponent,
    DropdownComponent,
    Select2Component,
    BlocFilterComponent,
    ModalComponent,
    FieldErrorComponent,
    ProgressSpinnerModule,
    DropdownModule,
    BlocGlobalIndicatorComponent,
    TooltipModule,
    IconValidationStatusComponent,
    IconRealisationStatusComponent,
    ScrollTopModule,
    CalendarComponent,
    BlocDashboardFilterComponent,
    CheckboxComponent,
    InputSwitchComponent,
    SliderModule,
    MultiselectComponent,
    ConfirmDialogModule,
    ToolbarModule,
    TabMenuModule,
    TabViewModule,
    DicoComponent,
    BacklogComponent,
    VersionComponent,
    AccordionModule,
    DocComponent,
    StructureComponent,
    StructureDicoComponent,
    TagComponent,    
  ],
  providers: [ MessageService, ConfirmationService]
})
export class UiModule { }
