import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ic-med-arg',
  templateUrl: './ic-med-arg.component.html',
  styleUrls: ['./ic-med-arg.component.sass']
})
export class IcMedArgComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
