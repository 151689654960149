import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-bloc-dashboard-filter',
  templateUrl: './bloc-dashboard-filter.component.html',
  styleUrls: ['./bloc-dashboard-filter.component.scss']
})
export class BlocDashboardFilterComponent implements OnInit {

  filterIndicator: any;
  filterTime: any;
  filterNature: any;
  filterTypeTime: any;
  filterEquipmentBadge: any;
  filterArbo: boolean;
  filterValidationClient: any;
  globalfilter: any;
  noteAbcmFilter: string;
  croFilter: boolean;
  @Input() currentFilters: any[] = [];
  @Input() dashboardActif = 'dashboardProject';
  @Input() dashboardTechniqueData: any;
  @Output() newFiltersEmitter = new EventEmitter();
  @Output() modeArboEmitter = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
    this.currentFilters['reference'] = {
      value: '',
      matchMode: 'contains'
    };
    // filtres
    this.globalfilter = {
      field: 'reference',
      placeholder: 'Réference',
      isIcon: false,
      filterValues: [],
    };
    this.filterIndicator = {
      field: 'filterIndicator',
      placeholder: 'Indicateur de risque',
      isIcon: true,
      filterValues: [
        {label: 'Point de vigilance', value: 'nbVigilance'},
        {label: 'Points critiques', value: 'nbCritique'},
        {label: 'Réalisation impossible', value: 'nbImpossibleRealisation'},
        {label: 'Aucun indicateur', value: 'nbAucun'},
      ],
    };
    this.filterTypeTime = {
      field: 'filterType',
      placeholder: 'Type de délai',
      isIcon: false,
      filterValues: [
        // {label: 'Validation client', value: 'valid'},
        {label: 'Approvisionnement', value: 'appro'},
        {label: 'Fabrication', value: 'fab'},
        {label: 'Validation client', value: 'val'},
      ],
    };
    this.filterTime = {
      field: 'filterTime',
      placeholder: 'Délai d\'appro',
      isIcon: false,
      filterValues: [
        {label: '-90 jours', value: 'less'},
        {label: 'de 90 à 120 jours', value: 'between'},
        {label: '+ 120 jours', value: 'more'},
      ],
    };
    this.filterNature = {
      field: 'nature',
    };
    this.filterEquipmentBadge = {
      field: 'badge',
      placeholder: 'Tous les éléments',
      isIcon: true,
      filterValues: [
        {label: 'Modifiés', value: 'update'},
        {label: 'Ajoutés', value: 'new'},
      ],
    };
    this.filterArbo = true;
    this.filterValidationClient = {
      field: 'clientValidation',
      placeholder: 'Validation client',
      isIcon: false,
      filterValues: [],
    };
  }

  filterChange(event): void {
    this.currentFilters[event.field] = {
        value: event.value,
        matchMode: event.matchMode
      };
    this.newFiltersEmitter.emit(Object.assign([], this.currentFilters));
  }

  arboChange(): void {
    this.modeArboEmitter.emit(this.filterArbo);
  }

  globalFilterChange(event): void {
    this.currentFilters['reference'] = {
      value: event,
      matchMode: 'contains'
    };
    this.newFiltersEmitter.emit(Object.assign([], this.currentFilters));
  }

  filterDashboardTechniqueNote(event): void {
    if (event === this.noteAbcmFilter) {
      this.noteAbcmFilter = '';
      this.filterChange({field: 'dtNoteAbcm', value: '', matchMode: 'equals'});
    } else {
      this.noteAbcmFilter = event;
      this.filterChange({field: 'dtNoteAbcm', value: event, matchMode: 'equals'});
    }
  }

  filterDashboardTechniqueCRO(): void {
    if (this.croFilter) {
      this.filterChange({field: 'dtTargetCostMissing', value: '', matchMode: 'equals'});
      this.croFilter = false;
    } else {
      this.filterChange({field: 'dtTargetCostMissing', value: true, matchMode: 'equals'});
      this.croFilter = true;
    }
  }

  // Remise à zéro des filtres
  resetFilters(): void {
    const referenceFilter = this.currentFilters['reference'];
    this.currentFilters = [];
    this.currentFilters['reference'] = referenceFilter;
    this.noteAbcmFilter = '';
    this.croFilter = false;
    this.newFiltersEmitter.emit(Object.assign([], this.currentFilters));
  }
}
