import { Injectable } from '@angular/core';
import {UserService} from './api/user.service';
import {StoreService} from './store.service';
import { LocalStorageService } from 'angular-web-storage';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  constructor(
      private storage: LocalStorageService,
      private userService: UserService,
      private storeService: StoreService,
  ) { }

  Init() {
    return new Promise<void>((resolve, reject) => {
      // a l'init si l'user est toujours connecté on doit récupérer le currentUser avant de charger le reste
      if (!this.storeService.getCurrentUser()) {
        const userId = this.storage.get('userId');
        if (userId) {
          this.userService.get(userId).subscribe(
              user => {
                user.companyName = user.company.name;
                // L'utilisateur courant est rafraichi dans le store
                this.storeService.refreshUser(user);
                resolve();
              },
              error => {
                // Si erreur de récuperation de l'utilisateur on laisse passer, le guard redirigera vers le login
                resolve();
              }
          );
        } else {
          // Si pas d'utilisateur à rafraichir on laisse passer, le guard redirigera vers le login
          resolve();
        }
      } else {
        // Si utilisateur déjà renseigné dans le store pas besoin de le rafraichir (ce cas ne devrait pas arriver)
        resolve();
      }
    });
  }
}
