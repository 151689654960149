import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ic-incoterm-chargement',
  templateUrl: './ic-incoterm-chargement.component.html',
  styleUrls: ['./ic-incoterm-chargement.component.scss']
})
export class IcIncotermChargementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
