import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-list-cell',
  templateUrl: './list-cell.component.html',
  styleUrls: ['./list-cell.component.scss'],
})
export class ListCellComponent implements OnInit {

  @Input() col;
  @Input() data;
  @Input() field;
  @Input() dataId = '';
  @Input() statuses: string[];
  @Output() dataFieldChange = new EventEmitter<string>();
  constructor() { }

  ngOnInit() {
  }

  handleChange(e) {
    this.data[this.field] = e;
    this.dataFieldChange.emit(this.data[this.field]);
  }
}
