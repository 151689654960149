import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit {

  @Input() value: 'new' | 'update' | number | null = null;
  @Input() color: 'black' | 'regular' | 'disabled' | 'critique' | 'vigilance' | 'valid' | null = null;

  constructor() { }

  ngOnInit(): void {
  }

}
