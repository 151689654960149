import {Component, Input} from '@angular/core';
import {AuthGuardService} from '../../service/auth/auth-guard.service';
import {StoreService} from '../../service/store.service';
import {XtlAction} from '../../interfaces/xtl-action';
import {ActionTypes} from '../../enums/action-types.enum';
import {AuthActionTypes} from '../../enums/auth-action-types.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent{
  constructor(public storeService: StoreService,
              public authGuardService: AuthGuardService) {}
  @Input() isDarkTheme: boolean;
  public displayMenu = false;
  authAction = AuthActionTypes;

  showMenu(): void {
    this.displayMenu = true;
  }
  hideMenu(): void {
    this.displayMenu = false;
  }
  logout() {
    const action: XtlAction = {
      type: ActionTypes.USER_LOGOUT,
      data: null
    };
    this.storeService.dispatch(action);
  }
}
