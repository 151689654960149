import { schema } from 'normalizr';

const companySchema = new schema.Entity('companies');
const companyListSchema = [companySchema];

export {companySchema};
export {companyListSchema};



