import { Injectable } from '@angular/core';
import {XtlReducer} from '@app/interfaces/xtl-reducer';
import {UserStory} from '@app/interfaces/userstory';
import {Observable} from 'rxjs';
import {NetworkService} from '@app/service/network.service';
import {MessageService} from 'primeng/api';
import {environment} from '@environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {normalize} from 'normalizr';
import {userStoryListSchema, userStorySchema} from '@app/model/xtl-project';

@Injectable({
  providedIn: 'root'
})
export class UserStoryService implements XtlReducer<UserStory>{

  constructor(private networkService: NetworkService,
              private messageService: MessageService
  ) { }

  create(data: Partial<UserStory>): Observable<{ error: string } | any> {
    return this.networkService
      .post(environment.apiUrl + '/user_stories', data)
      .pipe(
          tap(result => {
            this.messageService.add({
              severity: 'success',
              summary: 'User story ajoutée',
              detail: 'La User story a été correctement ajoutée.',
              life: 2000
            });
          }),
        map(result => normalize(result, userStorySchema)),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de création user story',
            detail: 'La création de la user story a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 10000
          });
          return [];
        })
      );

  }

  fetch(): Observable<any> {
    return undefined;
  }

  remove(target: UserStory): Observable<true | { error: string }> {
    return undefined;
  }

  update(data: Partial<UserStory>): Observable<{ error: string } | any> {
    return this.networkService
      .put(environment.apiUrl + '/user_stories/' + data.id, data)
      .pipe(
        tap(result => {
          this.messageService.add({
            severity: 'success',
            summary: 'User story modifiée',
            detail: 'La User story a été correctement modifiée.',
            life: 2000
          });
        }),
        map(result => normalize(result, userStorySchema)),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de modification de la user story',
            detail: 'La modification de la user story a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 10000
          });
          return [];
        })
      );
  }

  updateRanks(data: Partial<UserStory>): Observable<any | { error: string; }> {
    return this.networkService
      .post(environment.apiUrl + '/user_stories_put' , data)
      .pipe(
        tap(result => {
          this.messageService.add({
            severity: 'success',
            summary: 'Organisation modifiée',
            detail: 'L\'organisation des user stories a été correctement modifiée.',
            life: 2000
          });
        }),
        // map(result => normalize(result, topicSchema)),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de modification de la rubrique',
            detail: 'La modification de l\'organisation user stories a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 10000
          });
          return [];
        })
      );
  }

  get(id: number): Observable<any | { error: string; }> {
    return undefined
  }

}
