import { Component, OnInit, Input } from '@angular/core';
import {EquipmentStatus} from '../../../enums/status.enum';
import {ColorService} from '../../../service/helper/color.service';

@Component({
  selector: 'app-icon-realisation-status',
  templateUrl: './icon-realisation-status.component.html',
  styleUrls: ['./icon-realisation-status.component.scss']
})
export class IconRealisationStatusComponent implements OnInit {

  @Input() realisationStatus;
  equipmentStatus = EquipmentStatus;

  constructor(
    private colorService: ColorService
  ) { }

  ngOnInit(): void {
  }

}
