import {Injectable} from '@angular/core';
import {XtlStore} from '../interfaces/xtl-store';
import {XtlState} from '../interfaces/xtl-state';
import {XtlAction} from '../interfaces/xtl-action';
import {BehaviorSubject} from 'rxjs';
import {ActionTypes} from '../enums/action-types.enum';
import {map, distinctUntilChanged} from 'rxjs/operators';
import {
    backlogListSchema,
    backlogSchema,
    dictionaryListSchema,
    dictionarySchema,
    projectListSchema,
    projectSchema,
    tagListSchema,
    tagSchema,
    topicListSchema,
    topicSchema,
    topicDictionaryEntityListSchema,
    topicDictionaryEntitySchema,
    userStoryListSchema,
    userStorySchema,
    variableListSchema,
    variableSchema,
    versionListSchema,
    versionSchema
} from '../model/xtl-project';
import {denormalize} from 'normalizr';
import {ProjectService} from './api/project.service';
import {AuthService} from './auth/auth.service';
import {userListSchema, userSchema} from '@app/model/xtl-user';
import {User} from '@app/interfaces/user';
import merge from 'deepmerge';
import {UserService} from '@app/service/api/user.service';
import { UserStoryService } from './api/userStory.service';
import { isPlainObject } from 'is-plain-object';
import { BacklogService } from './api/backlog.service';
import { DicoService } from './api/dico.service';
import { VersionService } from './api/version.service';
import { TopicService } from './api/topic.service';
import { VariableService } from './api/variable.service';
import { DictionaryEntityService } from './api/dictionaryEntity.service';
import { TagService } from './api/tag.service';
import {MessageService} from 'primeng/api';
import {CompanyService} from '@app/service/api/company.service';
import {companyListSchema, companySchema} from '@app/model/xtl-company';
import {TopicDictionaryEntityService} from '@app/service/api/topicDictionaryEntity.service';

@Injectable({
  providedIn: 'root'
})
export class StoreService implements XtlStore<XtlState>{

  private _actionHistory = [];

  private _initialState =
      <XtlState><unknown>{
        currentUser: null,
        selectedItem: {},
        projects: {entities: {projects: {}, versions: {}, backlogs: {}, dictionaries: {}, tags: {}, users: {}}, result: []},
        users: {entities: {users: {}}, result: []},
        companies: {entities: {companies: {}}, result: []},
        versions: {entities: {versions: {}}, result: []},
        backlogs: {entities: {backlog: {}, topics: {}, userStories: {}, tags: {}}, result: []},
        dictionaries: {entities: {dictionary: {}, topicDictionaryEntity: {}, variables: {}, entities: {variables: {}}}, result: []},
        error: []
      };

  private _state = this._initialState;


  constructor(
      private projectService: ProjectService,
      private dicoService: DicoService,
      private backlogService: BacklogService,
      private userStoryService: UserStoryService,
      private authService: AuthService,
      private userService: UserService,
      private versionService: VersionService,
      private variableService: VariableService,
      private dictionaryEntityService: DictionaryEntityService,
      private topicService: TopicService,
      private topicDictionaryEntityService: TopicDictionaryEntityService,
      private tagService: TagService,
      private messageService: MessageService,
      private companyService: CompanyService
  ) { }

  private logHistory(action:XtlAction){
    const archivedAction = Object.assign({time:Date.now()},action);
    this._actionHistory.push(archivedAction);
    console.groupCollapsed('Action Log');
    // console.warn(archivedAction);
    console.groupCollapsed('Action History');
    console.table(this._actionHistory);
    console.groupEnd();
    console.groupEnd();

  }
  state$ = new BehaviorSubject<XtlState>(this._state);

  selectProjects(id?) {
    if(id){
      return this.state$.pipe(
          map(state => denormalize(state.projects.entities.projects[id], projectSchema, state.projects.entities)),
          distinctUntilChanged((x, y) => {
            return JSON.stringify(y) === JSON.stringify(x);
          }),
      );
    } else {

      return this.state$.pipe(
          map(state => denormalize(Object.keys(state.projects.entities.projects), projectListSchema, state.projects.entities)),
          distinctUntilChanged((x, y) => {
            return JSON.stringify(y) === JSON.stringify(x);
          }),
      );
    }
  }

    selectVersions(id?) {
        if(id){
            return this.state$.pipe(
                map(state => denormalize(state.projects.entities.versions[id], versionSchema, state.projects.entities)),
                distinctUntilChanged((x, y) => {
                    return JSON.stringify(y) === JSON.stringify(x);
                }),
            );
        } else {

            return this.state$.pipe(
                map(state => denormalize(Object.keys(state.projects.entities.versions), versionListSchema, state.projects.entities)),
                distinctUntilChanged((x, y) => {
                    return JSON.stringify(y) === JSON.stringify(x);
                }),
            );
        }
    }

  selectBacklogs(id?) {
    if(id){
      return this.state$.pipe(
          map(state => denormalize(state.backlogs.entities.backlog[id], backlogSchema, state.backlogs.entities)),
          distinctUntilChanged((x, y) => {
            return JSON.stringify(y) === JSON.stringify(x);
          }),
      );
    } else {

      return this.state$.pipe(
          map(state => denormalize(Object.keys(state.backlogs.entities.backlog), backlogListSchema, state.backlogs.entities)),
          distinctUntilChanged((x, y) => {
            return JSON.stringify(y) === JSON.stringify(x);
          }),
      );
    }
  }

  selectDictionaries(id?) {
    if(id){
      return this.state$.pipe(
          map(state => denormalize(state.dictionaries.entities.dictionary[id], dictionarySchema, state.dictionaries.entities)),
          distinctUntilChanged((x, y) => {
            return JSON.stringify(y) === JSON.stringify(x);
          }),
      );
    } else {

      return this.state$.pipe(
          map(state => denormalize(Object.keys(state.dictionaries.entities.dictionary), dictionaryListSchema, state.dictionaries.entities)),
          distinctUntilChanged((x, y) => {
            return JSON.stringify(y) === JSON.stringify(x);
          }),
      );
    }
  }

  selectBacklogTopics(id?) {
    if(id){
      return this.state$.pipe(
          map(state => denormalize(state.backlogs.entities.topics[id], topicSchema, state.backlogs.entities)),
          distinctUntilChanged((x, y) => {
            return JSON.stringify(y) === JSON.stringify(x);
          }),
      );
    } else {

      return this.state$.pipe(
          map(state => denormalize(Object.keys(state.backlogs.entities.topics), topicListSchema, state.backlogs.entities)),
          distinctUntilChanged((x, y) => {
            return JSON.stringify(y) === JSON.stringify(x);
          }),
      );
    }
  }

    selectDictionaryTopics(id?) {
        if(id){
            return this.state$.pipe(
                map(state => denormalize(state.dictionaries.entities.topicDictionaryEntity[id], topicDictionaryEntitySchema, state.dictionaries.entities)),
                distinctUntilChanged((x, y) => {
                    return JSON.stringify(y) === JSON.stringify(x);
                }),
            );
        } else {
            return this.state$.pipe(
                map(state => denormalize(Object.keys(state.dictionaries.entities.topicDictionaryEntity), topicDictionaryEntityListSchema, state.dictionaries.entities)),
                distinctUntilChanged((x, y) => {
                    return JSON.stringify(y) === JSON.stringify(x);
                }),
            );
        }
    }

  selectTags(id?) {
    if (id) {
      return this.state$.pipe(
        map(state => denormalize(state.backlogs.entities.tags[id], tagSchema, state.backlogs.entities)),
        distinctUntilChanged((x, y) => {
          return JSON.stringify(y) === JSON.stringify(x);
        }),
      );
    } else {
      return this.state$.pipe(
          map(state => denormalize(Object.keys(state.backlogs.entities.tags), tagListSchema, state.backlogs.entities)),
          distinctUntilChanged((x, y) => {
            return JSON.stringify(y) === JSON.stringify(x);
          }),
      );
    }
  }

  selectUserStories(id?) {
      if (id) {
        return this.state$.pipe(map(state => denormalize(state.backlogs.entities.userStories[id], userStorySchema, state.backlogs.entities)));

      } else {
          return this.state$.pipe(
              map(state => denormalize(Object.keys(state.backlogs.entities.userStories), userStoryListSchema, state.backlogs.entities)),
              distinctUntilChanged((x, y) => {
                  return JSON.stringify(y) === JSON.stringify(x);
              }),
          );

      }
  }

  selectUsers(id?) {
    if (id) {
      return this.state$.pipe(map(state => denormalize(state.users.entities.users[id], userSchema, state.users.entities)));

    } else {
      return this.state$.pipe(
          map(state => denormalize(Object.keys(state.users.entities.users), userListSchema, state.users.entities)),
          distinctUntilChanged((x, y) => {
            return JSON.stringify(y) === JSON.stringify(x);
          }),
    );

    }
  }

    selectProjectUsers() {
        return this.state$.pipe(
            map(state => denormalize(Object.keys(state.projects.entities.users), userListSchema, state.projects.entities)),
            distinctUntilChanged((x, y) => {
                return JSON.stringify(y) === JSON.stringify(x);
            }),
        );
    }

    selectCompanies(id?) {
        if (id) {
            return this.state$.pipe(map(state => denormalize(state.companies.entities.companies[id], companySchema, state.companies.entities)));

        } else {
            return this.state$.pipe(
                map(state => denormalize(Object.keys(state.companies.entities.companies), companyListSchema, state.companies.entities)),
                distinctUntilChanged((x, y) => {
                    return JSON.stringify(y) === JSON.stringify(x);
                }),
            );

        }
    }
  
  selectSelectedItem() {
    return this.state$.pipe(map(state => state.selectedItem));
  }

  getCurrentUser() {
    return this._state.currentUser;
  }

  refreshUser(User: Partial<User>) {
    this.state$.next(this._state = Object.assign({}, this._state, {
      currentUser: User,
      error: null
    }));
  }


  dispatch(action: XtlAction): void {
    // console.log(action);
    this.logHistory(action);

    switch (action.type) {

      case ActionTypes.SELECT_ITEM:
        this.state$.next(this._state = Object.assign({},this._state, {
          selectedItem:{id: action.data.id, type: action.data.actionType}
        }));
      break;

      case ActionTypes.FETCH_PROJECT:
        this.projectService.fetch().subscribe(
            result => {
              this._state.projects.entities = merge(this._state.projects.entities, result.entities, {isMergeableObject: isPlainObject});
              this.state$.next(this._state = Object.assign({}, this._state));
            }
        );
      break;

      case ActionTypes.FETCH_PROJECT_BY_USER_COMPANY:
        this.projectService.fetchByUserCompany(action.data).subscribe(
            result => {
                this._state.projects.entities = null;
                this._state.projects.entities = merge(this._state.projects.entities, result.entities, {isMergeableObject: isPlainObject});
                this.state$.next(this._state = Object.assign({}, this._state));
            }
        );
      break;

      case ActionTypes.GET_PROJECT:
        this.projectService.get(action.data).subscribe(
            result => {
              // Merge du détail du projet avec la liste des projets
              //   Object.keys(this._state.projects.entities).map((key)=> {
              //       this._state.projects.entities[key] = Object.assign(this._state.projects.entities[key],result.entities[key]);
              //   });

                this._state.projects.entities = merge(this._state.projects.entities, result.entities, {isMergeableObject: isPlainObject});
                // this._state.projects.entities = [];

                this.state$.next(this._state = Object.assign({}, this._state));
            }
        );
      break;

      case ActionTypes.ADD_PROJECT:
        this.projectService.create(action.data).subscribe(
            result => {
              // Merge de chaque entité de l'element du store (ici projects), avec l'entité correspondante du payload normalisé
              Object.keys(this._state.projects.entities).map((key) => {
                this._state.projects.entities[key] = Object.assign(this._state.projects.entities[key], result.entities[key]);
              });

              this.state$.next(this._state = Object.assign({}, this._state));
            }
        );
      break;

      case ActionTypes.UPDATE_PROJECT:
        this.projectService.update(action.data).subscribe(
            result => {
              //Merge de chaque entité de l'element du store (ici projects), avec l'entité correspondante du payload normalisé
              Object.keys(this._state.projects.entities).map((key)=> {
                this._state.projects.entities[key] = Object.assign(this._state.projects.entities[key],result.entities[key]);
              });

              this.state$.next(this._state = Object.assign({}, this._state));
            }
        );
      break;

      case ActionTypes.FETCH_BACKLOG:
        this.backlogService.fetch().subscribe(
            result => {
              this._state.backlogs.entities = merge(this._state.backlogs.entities, result.entities,{isMergeableObject: isPlainObject});
                this.state$.next(this._state = Object.assign({},this._state));
            }
        );
      break;

      case ActionTypes.GET_BACKLOG:
        this.backlogService.get(action.data).subscribe(
            result => {
                this._state.backlogs.entities = merge(this._state.backlogs.entities, result.entities,{isMergeableObject: isPlainObject});
                this.state$.next(this._state = Object.assign({},this._state));
            }
        );
      break;

      case ActionTypes.GET_DICO:
        this.dicoService.get(action.data).subscribe(
            result => {
                this._state.dictionaries.entities = merge(this._state.dictionaries.entities, result.entities,{isMergeableObject: isPlainObject});
                this.state$.next(this._state = Object.assign({},this._state));
            }
        );
      break;

      case ActionTypes.ADD_VARIABLE:
        this.variableService.create(action.data).subscribe(
            result => {
              // Merge de chaque entité de l'element du store , avec l'entité correspondante du payload normalisé
              Object.keys(this._state.dictionaries.entities).map((key)=> {
                this._state.dictionaries.entities[key] = Object.assign(this._state.dictionaries.entities[key], result.entities[key]);
              });
              // Ajout de l'id du backlog car il n'est pas dans l'objet normalisé
              this._state.dictionaries.entities.entities[result.entities.variables[result.result].dictionaryEntity.id].variables = [...this._state.dictionaries.entities.entities[result.entities.variables[result.result].dictionaryEntity.id].variables, result.result];

              this.state$.next(this._state = Object.assign({},this._state));
            }
        );
      break;

      case ActionTypes.UPDATE_VARIABLE:
        this.variableService.update(action.data).subscribe(
            result => {
              // Merge de chaque entité de l'element du store , avec l'entité correspondante du payload normalisé
              Object.keys(this._state.dictionaries.entities).map((key)=> {
                this._state.dictionaries.entities[key] = Object.assign(this._state.dictionaries.entities[key], result.entities[key]);
              });

              this.state$.next(this._state = Object.assign({},this._state));
            }
        );
      break;

      case ActionTypes.ADD_ENTITY:
        this.dictionaryEntityService.create(action.data).subscribe(
            result => {
              // Merge de chaque entité de l'element du store , avec l'entité correspondante du payload normalisé
              Object.keys(this._state.dictionaries.entities).map((key) => {
                this._state.dictionaries.entities[key] = Object.assign(this._state.dictionaries.entities[key], result.entities[key]);
              });
              // Ajout de l'id du backlog car il n'est pas dans l'objet normalisé
              this._state.dictionaries.entities.dictionary[result.entities.entities[result.result].dictionary.id].entities = [...this._state.dictionaries.entities.dictionary[result.entities.entities[result.result].dictionary.id].entities, result.result];
              this.state$.next(this._state = Object.assign({},this._state));
            }
        );
        break;

        case ActionTypes.UPDATE_ENTITY:
                this.dictionaryEntityService.update(action.data).subscribe(
                    result => {
                      // Merge de chaque entité de l'element du store , avec l'entité correspondante du payload normalisé
                        Object.keys(this._state.dictionaries.entities).map((key) => {
                        this._state.dictionaries.entities[key] = Object.assign(this._state.dictionaries.entities[key], result.entities[key]);
                      });
                      this.state$.next(this._state = Object.assign({}, this._state));
                    }
                );
                break;

        case ActionTypes.ADD_TOPIC:
            this.topicService.create(action.data.topic).subscribe(
                result => {
                    this.dispatch({type: ActionTypes.FETCH_TOPICS_BY_VERSION_ID, data: action.data.backlogId});
                }
            );
            break;

        case ActionTypes.ADD_TOPIC_DICTIONARY_ENTITY:
            this.topicDictionaryEntityService.create(action.data.topic).subscribe(
                result => {
                    this.dispatch({type: ActionTypes.FETCH_TOPICS_DICTIONARY_ENTITY_BY_VERSION_ID, data: action.data.backlogId});
                }
            );
            break;

      case ActionTypes.UPDATE_TOPIC:
        this.topicService.update(action.data).subscribe(
            result => {
              // Merge de chaque entité de l'element du store (ici projects), avec l'entité correspondante du payload normalisé
              Object.keys(this._state.backlogs.entities).map((key)=> {
                this._state.backlogs.entities[key] = Object.assign(this._state.backlogs.entities[key],result.entities[key]);
              });

              this.state$.next(this._state = Object.assign({}, this._state));
            }
        );
      break;

        case ActionTypes.UPDATE_TOPIC_DICTIONARY_ENTITY:
            this.topicDictionaryEntityService.update(action.data).subscribe(
                result => {
                    // Merge de chaque entité de l'element du store (ici projects), avec l'entité correspondante du payload normalisé
                    Object.keys(this._state.dictionaries.entities).map((key)=> {
                        this._state.dictionaries.entities[key] = Object.assign(this._state.dictionaries.entities[key],result.entities[key]);
                    });

                    this.state$.next(this._state = Object.assign({}, this._state));
                }
            );
            break;

      case ActionTypes.FETCH_TOPICS_BY_VERSION_ID:
        this.topicService.fetchTopicsByVersionId(action.data).subscribe(
            result => {
                    Object.keys(this._state.backlogs.entities).map((key) => {
                        this._state.backlogs.entities[key] = Object.assign(this._state.backlogs.entities[key], result.entities[key]);
                    });
                    this.state$.next(this._state = Object.assign({}, this._state));
            }
        );
    break;

        case ActionTypes.FETCH_TOPICS_DICTIONARY_ENTITY_BY_VERSION_ID:
            this.topicDictionaryEntityService.fetchTopicsByVersionId(action.data).subscribe(
                result => {
                    Object.keys(this._state.dictionaries.entities).map((key) => {
                        this._state.dictionaries.entities[key] = Object.assign(this._state.dictionaries.entities[key], result.entities[key]);
                    });
                    this.state$.next(this._state = Object.assign({}, this._state));
                }
            );
            break;

        case ActionTypes.UPDATE_RANKS_TOPIC:
            this.topicService.updateRanks(action.data).subscribe(
                result => {
                  let resultWithIdAsKey = [];
                  result.map(res => {
                    if (!resultWithIdAsKey[res.id]) resultWithIdAsKey[res.id] = [];
                    resultWithIdAsKey[res.id] = res;
                  });
                  this._state.backlogs.entities.topics = resultWithIdAsKey;
                  this.state$.next(this._state = Object.assign({}, this._state));
                  // window.location.reload();
                }
            );
          break;

        case ActionTypes.UPDATE_RANKS_TOPIC_DICTIONARY_ENTITY:
            this.topicDictionaryEntityService.updateRanks(action.data).subscribe(
                result => {
                    let resultWithIdAsKey = [];
                    result.map(res => {
                        if (!resultWithIdAsKey[res.id]) resultWithIdAsKey[res.id] = [];
                        resultWithIdAsKey[res.id] = res;
                    });
                    this._state.dictionaries.entities.topicDictionaryEntity = resultWithIdAsKey;
                    this.state$.next(this._state = Object.assign({}, this._state));
                    // window.location.reload();
                }
            );
            break;

      case ActionTypes.ADD_US:
        this.userStoryService.create(action.data.userStory).subscribe(
            result => {
                // Merge de chaque entité de l'element du store (ici projects), avec l'entité correspondante du payload normalisé
              Object.keys(this._state.backlogs.entities).map((key) => {
                this._state.backlogs.entities[key] = Object.assign(this._state.backlogs.entities[key], result.entities[key]);
              });
              this.dispatch({type: ActionTypes.GET_BACKLOG, data: action.data.backlogId});
              this.state$.next(this._state = Object.assign({}, this._state));
            }
        );
      break;

      case ActionTypes.UPDATE_US:
        this.userStoryService.update(action.data).subscribe(
            result => {
              //Merge de chaque entité de l'element du store (ici projects), avec l'entité correspondante du payload normalisé
              Object.keys(this._state.backlogs.entities).map((key) => {
                this._state.backlogs.entities[key] = Object.assign(this._state.backlogs.entities[key], result.entities[key]);
              });

              this.state$.next(this._state = Object.assign({}, this._state));
            }
        );
      break;

      case ActionTypes.UPDATE_RANKS_US:
        this.userStoryService.updateRanks(action.data).subscribe(
            result => {
                result.map(usRank => {
                this._state.backlogs.entities.userStories[usRank.id].rank = usRank.rank;
              });
              this.state$.next(this._state = Object.assign({}, this._state));
            }
        );
      break;

        case ActionTypes.UPDATE_RANKS_DICTIONARY_ENTITIES:
            this.dictionaryEntityService.updateRanks(action.data).subscribe(
                result => {
                    result.map(dictionaryEntityRank => {
                        this._state.dictionaries.entities.entities[dictionaryEntityRank.id].rank = dictionaryEntityRank.rank;
                    });
                    this.state$.next(this._state = Object.assign({}, this._state));
                }
            );
            break;

        case ActionTypes.UPDATE_RANKS_VARIABLES:
            this.variableService.updateRanks(action.data).subscribe(
                result => {
                    console.log(result)
                    result.map(variableRank => {
                        this._state.dictionaries.entities.variables[variableRank.id].rank = variableRank.rank;
                    });
                    this.state$.next(this._state = Object.assign({}, this._state));
                }
            );
            break;

      case ActionTypes.ADD_TAG:
        this.tagService.create(action.data).subscribe(
            result => {
              // Merge de chaque entité de l'element du store (ici projects), avec l'entité correspondante du payload normalisé
              Object.keys(this._state.backlogs.entities).map((key)=> {
                this._state.backlogs.entities[key] = Object.assign(this._state.backlogs.entities[key],result.entities[key]);
              });
              this.state$.next(this._state = Object.assign({},this._state));
            }
        );
      break;

      case ActionTypes.FETCH_TAG:
        this.tagService.fetch().subscribe(
            result => {
              this._state.projects.entities = merge(this._state.projects.entities, result.entities);
              this.state$.next(this._state = Object.assign({}, this._state));
            }
        );
      break;

    case ActionTypes.FETCH_TAGS_BY_VERSION_ID:
        this.tagService.fetchTagsByVersionId(action.data).subscribe(
            result => {
                this._state.backlogs.entities.tags = {};
                if (result.entities.tags) {
                    this._state.backlogs.entities.tags = merge(this._state.backlogs.entities.tags, result.entities.tags,{isMergeableObject: isPlainObject});
                    this.state$.next(this._state = Object.assign({},this._state));
                } else {
                    this._state.backlogs.entities.tags = {};
                    this.state$.next(this._state = Object.assign({},this._state));
                }
            }
        );
        break;

      case ActionTypes.UPDATE_TAG:
        this.tagService.update(action.data).subscribe(
            result => {
              console.log(result);
              //Merge de chaque entité de l'element du store (ici projects), avec l'entité correspondante du payload normalisé
              Object.keys(this._state.backlogs.entities).map((key)=> {
                this._state.backlogs.entities[key] = Object.assign(this._state.backlogs.entities[key],result.entities[key]);
              });

              this.state$.next(this._state = Object.assign({}, this._state));
            }
        );
      break;


      case ActionTypes.ADD_VERSION:
        this.versionService.create(action.data.version).subscribe(
            result => {
                // stocker version seul n'a pas de sens, on doit aller recuperer projet qui contient les nouvelles ref de backlog etc...
                this.dispatch({type: ActionTypes.GET_PROJECT, data: action.data.projectId});
            }
        );
      break;

      case ActionTypes.UPDATE_VERSION:
        this.versionService.update(action.data).subscribe(
            result => {
              console.log(result);
              //Merge de chaque entité de l'element du store (ici projects), avec l'entité correspondante du payload normalisé
              Object.keys(this._state.projects.entities).map((key)=> {
                this._state.projects.entities[key] = Object.assign(this._state.projects.entities[key],result.entities[key]);
              });

              this.state$.next(this._state = Object.assign({}, this._state));
            }
        );
      break;

      case ActionTypes.FETCH_USER:
        this.userService.fetch().subscribe(
            result => {
              this._state.users.entities = merge(this._state.users.entities, result.entities);
              this.state$.next(this._state = Object.assign({}, this._state));
            }
        );
      break;

      case ActionTypes.UPDATE_USER:
        this.userService.update(action.data).subscribe(
            result => {
                //Merge de chaque entité de l'element du store (ici users), avec l'entité correspondante du payload normalisé
                Object.keys(this._state.users.entities).map((key) => {
                    this._state.users.entities[key] = Object.assign(this._state.users.entities[key], result.entities[key]);
                });

                this.state$.next(this._state = Object.assign({}, this._state));
            }
        );
        break;

      case ActionTypes.ADD_USER:
        this.userService.create(action.data).subscribe(
            result => {
                // Merge de chaque entité de l'element du store (ici users), avec l'entité correspondante du payload normalisé
                Object.keys(this._state.users.entities).map((key) => {
                    this._state.users.entities[key] = Object.assign(this._state.users.entities[key],result.entities[key]);
                });

                this.state$.next(this._state = Object.assign({},this._state));
            }
        );
        break;

      case ActionTypes.USER_LOGIN:
        this.authService.create(action.data).subscribe(
            result => this.state$.next(this._state = Object.assign({}, this._state, {currentUser: result, error: null})),
            error => this.state$.next(this._state = Object.assign({}, this._state, {error})),
        );
      break;

      case ActionTypes.USER_LOGOUT:
        this.authService.remove();
        this.state$.next(this._state = Object.assign({}, this._state, {currentUser: null}));
      break;

    case ActionTypes.FETCH_COMPANY:
        this.companyService.fetch().subscribe(
            result => {
                this._state.companies.entities = merge(this._state.companies.entities, result.entities);
                this.state$.next(this._state = Object.assign({}, this._state));
            }
        );
        break;

    case ActionTypes.ADD_COMPANY:
        this.companyService.create(action.data).subscribe(
            result => {
                // Merge de chaque entité de l'element du store (ici users), avec l'entité correspondante du payload normalisé
                Object.keys(this._state.companies.entities).map((key) => {
                    this._state.companies.entities[key] = Object.assign(this._state.companies.entities[key], result.entities[key]);
                });

                this.state$.next(this._state = Object.assign({}, this._state));
            }
        );
        break;


      default:
      break;

    }
  }
}
