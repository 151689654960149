import { Injectable } from '@angular/core';
import { Tag } from '@app/interfaces/tag';
import { XtlReducer } from '@app/interfaces/xtl-reducer';
import {tagListSchema, tagSchema, topicListSchema} from '@app/model/xtl-project';
import { environment } from '@environments/environment';
import { normalize } from 'normalizr';
import { MessageService } from 'primeng/api';
import {Observable, catchError, map, tap} from 'rxjs';
import { NetworkService } from '../network.service';

@Injectable({
  providedIn: 'root'
})
export class TagService implements XtlReducer<Tag>{

  constructor(
      private networkService: NetworkService,
      private messageService: MessageService
  ) { }
  create(data: Partial<Tag>): Observable<any | { error: string; }> {
    return this.networkService
      .post(environment.apiUrl + '/tags', data)
      .pipe(
        tap(result => {
          this.messageService.add({
            severity: 'success',
            summary: 'Tag créé',
            detail: 'Le tag a été correctement créé.',
            life: 5000
          });
        }),
          map(result => normalize(result, tagSchema)),
          catchError((err, caught) => {
          console.log(err); // TODO catcher le detail de l'erreur pour traiter le cas duplicate_username
          // (pour le moment ça choppe un 'Bad request' sans detail
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de création tag',
            detail: 'La création du tag a échoué, si le problème persiste merci de contacter un administrateur.',
            life: 10000
          });
          return [];
        })
      );
  }
  update(data: Partial<Tag>): Observable<any | { error: string; }> {
    return this.networkService
      .put(environment.apiUrl + '/tags/' + data.id, data)
      .pipe(
        tap(result => {
          this.messageService.add({
            severity: 'success',
            summary: 'Tag modifié',
            detail: 'le tag a été correctement modifié.',
            life: 10000
          });
        }),
        map(result => normalize(result, tagSchema)),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de modification tag',
            detail: 'La modification du tag a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 10000
          });
          return [];
        })
      );
  }
  remove(target: Tag): Observable<true | { error: string; }> {
    throw new Error('Method not implemented.');
  }
  fetch(): Observable<any> {
    return this.networkService
    .get(environment.apiUrl + '/tags')
    .pipe(
      map(result => normalize(result, tagListSchema)),
      catchError((err, caught) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erreur de récupération des comptes utilisateurs',
          detail: 'Le chargement des comptes utilisateurs a échoué, si le problème persiste merci de contacter un administrateur.',
          life: 10000
        });
        return [];
      })
    );
  }

  fetchTagsByVersionId(versionId: string): Observable<any> {
      return this.networkService
          .get(environment.apiUrl + '/tags?versionTag.id%5B%5D=' + versionId)
          .pipe(
              map(
                  result => normalize(result, tagListSchema)
              ),
              catchError((err, caught) => {
                  this.messageService.add({
                      severity: 'error',
                      summary: 'Erreur de récupération des topics',
                      detail: 'Le chargement des projets a échoué, si le problème persiste merci de contacter un administrateur.',
                      life: 10000
                  });
                  return [];
              })
          );
  }
}
