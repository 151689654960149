import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-bloc-filter',
  templateUrl: './bloc-filter.component.html',
  styleUrls: ['./bloc-filter.component.scss']
})
export class BlocFilterComponent {

  @Input() filters: Array<{
    field: string,
    filterType: any,
    placeholder: string,
    isIcon: boolean,
    filterValues: Array<{
      label: string,
      value: string
    }>
  }> = [];
  @Input() linkBtn: Array<{
    label: string,
    icon: string,
    link: string
  }> = [];
  @Input() globalFilter: boolean;
  @Input() globalFiltervalue = '';
  @Output() newGlobalSearchEmitter = new EventEmitter();
  @Output() newFiltersEmitter = new EventEmitter();

  @Input() currentFilters: any[] = [];
  constructor() { }

  filterChange(event): void {
      this.currentFilters[event.field] =
        {
          value: event.value,
          matchMode: event.matchMode
        };
      this.newFiltersEmitter.emit(Object.assign([], this.currentFilters));
  }
  globalFilterChange(event): void {
    this.newGlobalSearchEmitter.emit(event);
  }
}
