import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Project } from 'src/app/interfaces/project';
import { progress } from '@app/enums/progress.enum';
import {MenuItem} from 'primeng/api';
import { ProjectService } from '@app/service/api/project.service';
import {ActionTypes} from '@app/enums/action-types.enum';
import {StoreService} from '@app/service/store.service';
import { take } from 'rxjs/operators';

interface Version {
  name: string,
  id: number
}

@Component({
  selector: 'app-page-project-detail',
  templateUrl: './page-project-detail.component.html',
  styleUrls: ['./page-project-detail.component.scss']
})
export class PageProjectDetailComponent implements OnInit {

  @ViewChild('menuItems') menu: MenuItem[];

  items: MenuItem[];

  activeItem: MenuItem;

  projectId: number;

  project: Project;

  versions: Version[] = [];

  selectedVersion: Version;

  getRole: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private projectService: ProjectService,
    private store: StoreService
  ) { }

  ngOnInit(): void {

    this.getRole = this.store.getCurrentUser().role;
    this.projectId = Number(this.activatedRoute.snapshot.paramMap.get('id'));
    this.store.dispatch({type: ActionTypes.GET_PROJECT, data: this.projectId});
    this.store.dispatch({type: ActionTypes.FETCH_USER, data: null});
    if (this.getRole == 'ROLE_ADMIN') {
      this.items = [
        {label: 'Backlog', icon: 'pi pi-fw pi-home'},
        {label: 'Dico des données', icon: 'pi pi-fw pi-calendar'},
        {label: 'Versions', icon: 'pi pi-fw pi-pencil'},
        {label: 'Tags', icon: 'pi pi-fw pi-cog'},
        {label: 'Documentation', icon: 'pi pi-fw pi-file'},
      ];
    }
    if (this.getRole == 'ROLE_USER') {
      this.items = [
        {label: 'Backlog', icon: 'pi pi-fw pi-home'},
        {label: 'Dico des données', icon: 'pi pi-fw pi-calendar'},
      ];
    }
    this.activeItem = this.items[0];

    if (this.projectId)
    {
      this.store.selectProjects(this.projectId).subscribe(project => {
        if (project && project.versions){
          this.project = project;
          this.versions = [];
          project.versions.map(version => {this.versions = [version, ...this.versions]; });
          this.selectedVersion = this.versions[0];
        }
      });
    }
  }

  activateMenu(){
    this.activeItem =this.menu['activeItem'];
    console.log(this.activeItem);
  }

  onVersionChange(event){
    this.selectedVersion = event;
  }

}
