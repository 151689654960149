import { identifierModuleUrl } from '@angular/compiler';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActionTypes } from '@app/enums/action-types.enum';
import { Topic } from '@app/interfaces/topic';
import { StoreService } from '@app/service/store.service';
import { TreeNode } from 'primeng/api';
import {TreeDragDropService} from 'primeng/api';

@Component({
  selector: 'app-structure',
  templateUrl: './structure.component.html',
  providers: [TreeDragDropService],
  styleUrls: ['./structure.component.scss']
})
export class StructureComponent implements OnInit {
  @Input() userStoriesBytopics: any[] = [] ;
  @Input() topics: Topic[] = [] ;
  treeTopics: TreeNode[] = [];


  constructor(private store: StoreService) { }

  ngOnInit(): void {

  }

  ngOnChanges(change: SimpleChanges) {
    this.transformToTreeTopics()
  }

  onDrop(event) {
    if (
      // deplacement d'un topic par rapport a un autre topic
      (event.originalEvent.target.nodeName == 'LI' && event.dragNode.droppable && event.dropNode.droppable) ||
      // deplacement d'une us par rapport a une autre us
      (event.originalEvent.target.nodeName == 'LI' && !event.dragNode.droppable && !event.dropNode.droppable ) ||
      // deplacement d'une us dans un autre topic
      (event.originalEvent.target.nodeName != 'LI' && !event.dragNode.droppable && event.dropNode.droppable )
    ) {
      event.accept();
    }
  }

  updateRanks(){
    let topicRank = 0;
    let usRank = 0;
    let topicsToUpdate:Topic[] = [];
    let userStoriesToUpdate:Topic[] = [];
    this.treeTopics.map(treeTopic => {
      let topicToUpdate:Topic[] = this.topics.filter(topic=>topic.id == treeTopic.data.id)
      topicToUpdate[0].rank = topicRank;
      topicsToUpdate = [...topicsToUpdate, topicToUpdate[0]]
      topicRank++;
      treeTopic.children.map(userStory=>{
        let userStoryToUpdate:any = {id:userStory.data, rank:usRank};
        userStoriesToUpdate = [...userStoriesToUpdate, userStoryToUpdate]
        usRank++;
      });
    });
    this.store.dispatch({type:ActionTypes.UPDATE_RANKS_TOPIC, data:topicsToUpdate});
    this.store.dispatch({type:ActionTypes.UPDATE_RANKS_US, data:userStoriesToUpdate});
  }

  transformToTreeTopics(){
    this.treeTopics = [];
    Object.entries(this.userStoriesBytopics).map((k,v) => {
      let node:TreeNode  = {data:this.getTopicByName(k[0]),label:k[0],droppable:true,children:this.getChildren(k[1])}
      this.treeTopics = [...this.treeTopics, node]
    });
  }

  getTopicByName(name:string){
    let topic =  this.topics.filter(topic=>
      topic.name == name
    )
    return topic[0];
  }

  getChildren(usArray):any[]{
    let child = []
    usArray.map(us=>{
      child = [...child, {data:us.id,label:us.title,rank:us.rank, droppable:false}]
    })
     
    return child;
  }

}
