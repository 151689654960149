import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {map, tap, catchError} from 'rxjs/operators';
import {User} from '@app/interfaces/user';
import {environment} from '@environments/environment';
import {NetworkService} from '../network.service';
import {XtlReducer} from '@app/interfaces/xtl-reducer';
import {MessageService} from 'primeng/api';
import { LocalStorageService } from 'angular-web-storage';

@Injectable({
  providedIn: 'root'
})

export class AuthService implements XtlReducer<User> {
  fetch(): Observable<any> {
    throw new Error('Method not implemented.');
  }
  update(data: Partial<User>): Observable<User | { error: string; }> {
    throw new Error('Method not implemented.');
  }

  constructor(
    private networkService: NetworkService,
    private messageService: MessageService,
    private storage: LocalStorageService
    ) {}

  create(data: {email: string, password: string}): Observable<any | { error: string; }> {
    return this.networkService.post(environment.apiUrl + '/login_check', data).pipe(
        tap(result => {
          this.storage.clear();
          // @ts-ignore
          this.storage.set('jwtToken', result.token);
        }),
        tap(result =>
            // @ts-ignore
      this.storage.set('userId', result.id)),
        map(result =>
      this.responseToUserAndToken(result)),
        catchError((err) => {
            this.messageService.add({
                severity: 'error',
                summary: 'Erreur d\'authentification',
                detail: 'L\'authentification a échoué, le login et/ou le mot de passe n\'est pas valide.',
                life: 10000
            });
            return [];
        })
    );
  }
  remove(): Observable<true | { error: string }> {
    this.storage.clear();
    return;
  }

    // Methode de changement du mot de passe utilisateur
    changePassword(data: {token: string, email: string, password: string}): Observable<any | {error: string; }> {
        return this.networkService.post(environment.apiUrl + '/change_password', data);
    }
    // Methode de demande de réinitialisation du mot de passe
    resetPassword(data: {email: string}): Observable<any | {error: string; }> {
        return this.networkService.post(environment.apiUrl + '/reset_password', data).pipe(
            tap( result => console.log(result))
        );
    }
    // Methode de demande de vérification de token de reset password
    checkResetPasswordToken(data: {token: string, email: string}): Observable<any | {error: string; }> {
        return this.networkService.get(environment.apiUrl + '/check_reset_token?token=' + data.token + '&email=' + data.email).pipe(
            tap( result => console.log(result))
        );
    }

    private responseToUserAndToken(result) {
    return {
        id: result.id,
        civility: result.civility,
        lastName: result.lastName,
        firstName: result.firstName,
        // userName: result.userName,
        role: result.roles[0],
        companyName: result.companyName,
    };
  }
}
