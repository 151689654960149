export enum ActionTypes {
  USER_LOGIN = 'USER_LOGIN',
  USER_LOGOUT = 'USER_LOGOUT',
  UPDATE_USER = 'UPDATE_USER',
  ADD_USER = 'ADD_USER',

  FETCH_PROJECT = 'FETCH_PROJECT',
  FETCH_PROJECT_BY_USER_COMPANY = 'FETCH_PROJECT_BY_USER_COMPANY',
  GET_PROJECT = 'GET_PROJECT',
  ADD_PROJECT = 'ADD_PROJECT',
  UPDATE_PROJECT = 'UPDATE_PROJECT',

  ADD_US = 'ADD_US',
  UPDATE_US = 'UPDATE_US',
  UPDATE_RANKS_US = 'UPDATE_RANKS_US',
  UPDATE_RANKS_DICTIONARY_ENTITIES = 'UPDATE_RANKS_DICTIONARY_ENTITIES',
  UPDATE_RANKS_VARIABLES = 'UPDATE_RANKS_VARIABLES',

  ADD_VARIABLE = 'ADD_VARIABLE',
  UPDATE_VARIABLE = 'UPDATE_VARIABLE',

  ADD_ENTITY = 'ADD_ENTITY',
  UPDATE_ENTITY = 'UPDATE_ENTITY',

  FETCH_USER = 'FETCH_USER',

  FETCH_BACKLOG = 'FETCH_BACKLOG',
  GET_BACKLOG = 'GET_BACKLOG',

  FETCH_DICO = 'FETCH_DICO',
  GET_DICO = 'GET_DICO',

  ADD_VERSION = 'ADD_VERSION',
  UPDATE_VERSION = 'UPDATE_VERSION',

  ADD_TAG = 'ADD_TAG',
  UPDATE_TAG = 'UPDATE_TAG',
  FETCH_TAG = 'FETCH_TAG',

  ADD_TOPIC = 'ADD_TOPIC',
  ADD_TOPIC_DICTIONARY_ENTITY = 'ADD_TOPIC_DICTIONARY_ENTITY',
  UPDATE_TOPIC = 'UPDATE_TOPIC',
  UPDATE_TOPIC_DICTIONARY_ENTITY = 'UPDATE_TOPIC_DICTIONARY_ENTITY',
  UPDATE_RANKS_TOPIC = 'UPDATE_RANKS_TOPIC',
  UPDATE_RANKS_TOPIC_DICTIONARY_ENTITY = 'UPDATE_RANKS_TOPIC_DICTIONARY_ENTITY',

  SELECT_ITEM = 'SELECT_ITEM',
  APP_INIT = 'APP_INIT',
  FETCH_TOPICS_BY_VERSION_ID = 'FETCH_TOPICS_BY_VERSION_ID',
  FETCH_TOPICS_DICTIONARY_ENTITY_BY_VERSION_ID = 'FETCH_TOPICS_DICTIONARY_ENTITY_BY_VERSION_ID',
  FETCH_TAGS_BY_VERSION_ID = 'FETCH_TAGS_BY_VERSION_ID',
  ADD_COMPANY = 'ADD_COMPANY',
  FETCH_COMPANY = 'FETCH_COMPANY',
}
