import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ic-kit',
  templateUrl: './ic-kit.component.html',
  styleUrls: ['./ic-kit.component.sass']
})
export class IcKitComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
