import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {XtlAction} from '../../interfaces/xtl-action';
import {ActionTypes} from '../../enums/action-types.enum';
import { StoreService } from '../store.service';
import {Router} from '@angular/router';
import {MessageService} from 'primeng/api';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private store: StoreService,
                private messageService: MessageService,
                private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            // Cas de l'authentification expirée
            if (err.status === 401 && this.router.url !== '/login') {
                const action: XtlAction = {
                    type: ActionTypes.USER_LOGOUT,
                    data: null
                };
                this.store.dispatch(action);
                this.messageService.add({
                    severity: 'warn',
                    summary: 'Votre Session a expirée',
                    detail: 'Votre Session a expirée, merci de vous reconnecter.',
                    life: 5000
                });
                this.router.navigate(['/login']);
            }

            const error = err.error.message || err.error.error || err.statusText;
            return throwError(error);
        }));
    }
}
