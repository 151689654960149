import {Component, EventEmitter, Input, OnChanges, Output, ViewChild} from '@angular/core';
import {InputSwitch} from 'primeng/inputswitch';

@Component({
  selector: 'app-input-switch',
  templateUrl: './input-switch.component.html',
  styleUrls: ['./input-switch.component.scss']
})
export class InputSwitchComponent implements OnChanges {

  @Input() value;
  @Input() hasChildren;
  @Input() disabled = false;
  @Output() onChange = new EventEmitter<any>();
  @ViewChild('inputSwitch') inputSwitch: InputSwitch;

  constructor() { }

  ngOnChanges(): void {
    if (this.inputSwitch !== undefined) {
      this.inputSwitch.writeValue(this.value);
    }
  }

  dataFieldChange(): void {
    if (!this.value && this.hasChildren) {
      this.inputSwitch.writeValue(false);
    }
    this.onChange.emit(!this.value);
  }
}
