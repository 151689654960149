import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ic-piece-achetee',
  templateUrl: './ic-piece-achetee.component.html',
  styleUrls: ['./ic-piece-achetee.component.sass']
})
export class IcPieceAcheteeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
