import { NgModule } from '@angular/core';

import {ButtonModule} from 'primeng/button';
import {SidebarModule} from 'primeng/sidebar';
import {BadgeModule} from 'primeng/badge';
import {ToastModule} from 'primeng/toast';
import {RouterModule} from '@angular/router';
import {TableModule} from 'primeng/table';
import {InputSwitchModule} from 'primeng/inputswitch';
import {CheckboxModule} from 'primeng/checkbox';
import {TimelineModule} from 'primeng/timeline';
import {DialogModule} from 'primeng/dialog';
import { PasswordModule } from 'primeng/password';
import {InputTextModule} from 'primeng/inputtext';
import { HttpClientModule } from '@angular/common/http';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {TooltipModule} from 'primeng/tooltip';
import {EditorModule} from 'primeng/editor';
import {SelectButtonModule} from 'primeng/selectbutton';
import { NgxTextDiffModule } from 'ngx-text-diff';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import { TagModule } from 'primeng/tag';
import {ColorPickerModule} from 'primeng/colorpicker';

@NgModule({
  declarations: [],
  exports: [
    ButtonModule,
    SidebarModule,
    RouterModule,
    BadgeModule,
    TableModule,
    ToastModule,
    InputSwitchModule,
    InputTextModule,
    ProgressSpinnerModule,
    CheckboxModule,
    TimelineModule,
    DialogModule,
    PasswordModule,
    HttpClientModule,
    MessagesModule,
    MessageModule,
    TooltipModule,
    EditorModule,
    SelectButtonModule,
    NgxTextDiffModule,
    OverlayPanelModule,
    ScrollPanelModule,
    TagModule,
    ColorPickerModule
  ]
})
export class SharedModule { }
