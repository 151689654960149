import { Injectable } from '@angular/core';
import { Variable } from '@app/interfaces/variable';
import { XtlReducer } from '@app/interfaces/xtl-reducer';
import { variableSchema } from '@app/model/xtl-project';
import { environment } from '@environments/environment';
import { normalize } from 'normalizr';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { NetworkService } from '../network.service';

@Injectable({
  providedIn: 'root'
})
export class VariableService implements XtlReducer<Variable>{

  constructor(private networkService: NetworkService,
    private messageService: MessageService
  ) { }

  create(data: Partial<Variable>): Observable<any | { error: string; }> {
    return this.networkService
      .post(environment.apiUrl + '/variables', data)
      .pipe(
          tap(result => {
              this.messageService.add({
                  severity: 'success',
                  summary: 'Variable ajoutée',
                  detail: 'La variable a été correctement modifiée.',
                  life: 2000
              });
          }),
        map(result => normalize(result, variableSchema)),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de création de la variable',
            detail: 'La création de la variable a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 10000
          });
          return [];
        })
      );
  }
  update(data: Partial<Variable>): Observable<any | { error: string; }> {
    return this.networkService
      .put(environment.apiUrl + '/variables/' + data.id, data)
      .pipe(
        tap(result => {
          this.messageService.add({
            severity: 'success',
            summary: 'Variable modifiée',
            detail: 'Lavariable a été correctement modifiée.',
            life: 2000
          });
        }),
        map(result => normalize(result, variableSchema)),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de modification de la variable',
            detail: 'La modification de la variable a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 10000
          });
          return [];
        })
      );
  }

    updateRanks(data: Partial<Variable>): Observable<any | { error: string; }> {
        return this.networkService
            .post(environment.apiUrl + '/variables_put' , data)
            .pipe(
                tap(result => {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Organisation modifiée',
                        detail: 'L\'organisation des variables a été correctement modifiée.',
                        life: 2000
                    });
                }),
                catchError((err, caught) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Erreur de modification de la rubrique',
                        detail: 'La modification de l\'organisation variable a échouée, si le problème persiste merci de contacter un administrateur.',
                        life: 10000
                    });
                    return [];
                })
            );
    }

  remove(target: Variable): Observable<true | { error: string; }> {
    throw new Error('Method not implemented.');
  }
  fetch(data: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
}
