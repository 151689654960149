import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuardService} from './service/auth/auth-guard.service';
import {PageLoginComponent} from './page/page-login/page-login.component';
import {PageUserListComponent} from './page/page-user-list/page-user-list.component';
import {PageProjectDetailComponent} from './page/page-project-detail/page-project-detail.component';
import { PageProjectListComponent } from './page/page-project-list/page-project-list.component';
import {PageResetPasswordComponent} from '@app/page/page-reset-password/page-reset-password.component';
import {PageActivateAccountComponent} from '@app/page/page-activate-account/page-activate-account.component';
import {PageChangePasswordComponent} from '@app/page/page-change-password/page-change-password.component';

const routes: Routes = [
  {
    path: 'login', component: PageLoginComponent
  },
  {
    path: 'project-list', component: PageProjectListComponent, canActivate: [AuthGuardService]
  },
  {
    path: 'activate-account', component: PageActivateAccountComponent
  },
  {
    path: 'change-password', component: PageChangePasswordComponent
  },
  {
    path: 'user-list', component: PageUserListComponent, canActivate: [AuthGuardService]
  },
    {
    path: 'project-create', component: PageProjectDetailComponent, canActivate: [AuthGuardService]
  },
  {
    path: 'project-detail/:id', component: PageProjectDetailComponent, canActivate: [AuthGuardService]
  },
  {
    path: 'reset-password', component: PageResetPasswordComponent
  },

  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '**' , redirectTo: 'login'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
