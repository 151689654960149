import {Component, Input, OnInit, Output, EventEmitter, OnChanges} from '@angular/core';
import { ClientEnum } from '../../enums/Client.enum';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit, OnChanges {

  clientEnum = ClientEnum;
  selectedValue;
  @Input() field: string;
  @Input() isIcon: boolean;
  @Input() filterValues: any[];
  @Input() placeholder: string;
  @Input() styleClass: string;
  @Input() defaultFilter: string;
  @Input() matchMode = 'equals';
  @Output() change = new EventEmitter<any>();

  ngOnInit(): void {
  }

  ngOnChanges() {
    // set la valeur du multiselect input
    if (this.defaultFilter !== undefined && this.defaultFilter !== null) {
      this.selectedValue = this.defaultFilter;
    }
  }

  handleChange(event){
    const filterObject = {
      value: event,
      field: this.field,
      matchMode: this.matchMode,
    };
    this.change.emit(filterObject);
  }
}
