import { Component, OnInit } from '@angular/core';

import { AuthService } from './service/auth/auth.service';
import { Router } from '@angular/router';
import { Project } from './model';
import {StoreService} from '@app/service/store.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  currentProject: Project;

    constructor(
    private authenticationService: AuthService,
    private router: Router,
    private store: StoreService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.store.state$.subscribe(
        state => {
          console.groupCollapsed('State');
          console.log(state);
          console.groupEnd();
        }
    );
  }

  ngOnInit() {

  }

  logout() {
    // this.authenticationService.logout();
    // this.router.navigate(['/login']);
  }
}
