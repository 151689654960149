import { Component, Input, OnInit } from '@angular/core';
import { ActionTypes } from '@app/enums/action-types.enum';
import { User } from '@app/interfaces/user';
import { Version } from '@app/interfaces/version';
import { StoreService } from '@app/service/store.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.css']
})
export class VersionComponent implements OnInit {

  @Input() versions: Version[];
  @Input() projectId: number;
  @Input() currentVersion: Version;

  versionDialog: boolean;

  submitted: boolean;

  version: Version;

  users: any[] = [];

  versionType: any[] = [];

  selectedVersionType: string;

  versionTableDatas: any[] = [];

  versionTypeEditDefault = 'mineur';

  versionForm: FormGroup;

    getRole: string;

    constructor(private confirmationService: ConfirmationService,
              private store: StoreService,
              private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    console.log('INIT version');
      this.getRole = this.store.getCurrentUser().role;
      this.versionTableDatas = this.versions;
    this.store.selectProjectUsers().subscribe(
      users => {
        if (users){
          users.map(user => this.users = [...this.users, {label: user.firstName + ' ' + user.lastName, value: user.id}]);
        }
      });
    this.versionType = [
      {name: 'Majeur', code: 'Majeur'},
      {name: 'Mineur', code: 'Mineur'},
    ];

    this.versionForm = this.formBuilder.group({
          description: ['', Validators.required],
          author: ['', Validators.required],
          reviewer: ['', Validators.required],
          versionType: ['', Validators.required]
      });
  }

    get f() { return this.versionForm.controls; }

    getLastVersion(){

  }

  openNew() {
    this.version = {};
    this.submitted = false;
    this.versionDialog = true;
  }

  editVersion(version: any) {
    this.version = {...version};
    this.versionDialog = true;
  }

  deleteVersion(version: any) {
      this.confirmationService.confirm({
          message: 'Are you sure you want to delete ' + version.name + '?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
              this.versions = this.versions.filter(val => val.id !== version.id);
              this.version = {};
          }
      });
  }

  hideDialog() {
      this.versionDialog = false;
      this.submitted = false;
  }

  saveVersion() {
      this.submitted = true;
      if (this.versionForm.invalid) {
          return;
      }
      if (this.version.id) {
          this.versionTableDatas[this.findIndexById(this.version.id)] = this.version;
          this.store.dispatch({type: ActionTypes.UPDATE_VERSION, data: this.version});
      } else {
          const actualVersion = parseFloat(this.versions[0].name);
          let newVersionName;
          this.version.date = new Date();
          this.version.project = {id: this.projectId};
          this.version.lastVersion = this.currentVersion.id;

          if (this.selectedVersionType == 'Majeur') {
              if (this.versions[0].name.split('.')[1] == '0') {
                  newVersionName = (actualVersion + 1).toFixed(1);
              } else {
                  newVersionName = Math.ceil(actualVersion).toFixed(1);
              }
              this.version.name = newVersionName.toString();
          } else {
              newVersionName = actualVersion + 0.1;
              const roundNewVersionName = Math.round((newVersionName + Number.EPSILON) * 100) / 100;
              console.log(roundNewVersionName);
              this.version.name = roundNewVersionName.toString();
          }
          this.store.dispatch({type: ActionTypes.ADD_VERSION, data: {version: this.version, projectId: this.projectId}});
          this.version = {...this.version, id: this.currentVersion.id + 1 };
          this.versionTableDatas.unshift(this.version);
      }
      this.versionDialog = false;
      this.version = {};
  }

    findIndexById(id: number): number {
        let index = -1;
        for (let i = 0; i < this.versionTableDatas.length; i++) {
            if (this.versionTableDatas[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }
}
