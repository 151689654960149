export enum EquipmentStatus {
  NONE = 'STATUS_NONE',
  TODO = 'STATUS_TO_DO',
  DONE = 'STATUS_DONE',
  LATE = 'STATUS_LATE',
  VIGILANCE = 'STATUS_VIGILANCE',
  IMPOSSIBLE = 'STATUS_REQUIRED',
}

export enum OrderStatus {
  ACTIVE = 'ORDER_ACTIVE',
  TEMPORARY = 'ORDER_TEMPORARY',
  SUSPENDED = 'ORDER_SUSPENDED',
  EXPIRED = 'ORDER_EXPIRED'
}

export enum ClientValidationType {
  SCHEMA_ELECTRIQUE = 'SCHEMA_ELECTRIQUE',
  PLAN_ENCOMBREMENT = 'PLAN_ENCOMBREMENT',
}
