import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ic-incoterm-export-customs',
  templateUrl: './ic-incoterm-export-customs.component.html',
  styleUrls: ['./ic-incoterm-export-customs.component.scss']
})
export class IcIncotermExportCustomsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
