import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AuthService} from './auth/auth.service';
import {StoreService} from './store.service';
import {LoaderService} from './loader.service';
import {NetworkService} from './network.service';
import {PdfService} from './pdf.service';
import {PdfEncodedProviderService} from './pdf-encoded-provider.service';
import { MessageService } from 'primeng/api';
import { ProjectService } from '@app/service/api/project.service';
import { DicoService } from '@app/service/api/dico.service';
import { TopicService } from '@app/service/api/topic.service';
import { UserStoryService } from './api/userStory.service';
import { VersionService } from './api/version.service';
import { VariableService } from './api/variable.service';
import { DictionaryEntityService } from './api/dictionaryEntity.service';
import { TagService } from './api/tag.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    AuthService,
    MessageService,
    StoreService,
    LoaderService,
    NetworkService,
    PdfService,
    PdfEncodedProviderService,
    ProjectService,
    StoreService,
    DicoService,
    UserStoryService,
    VersionService,
    VariableService,
    DictionaryEntityService,
    TopicService,
    TagService
  ]
})
export class ServiceModule { }
