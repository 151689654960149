import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ClientEnum} from '../../enums/Client.enum';

@Component({
  selector: 'app-select2',
  templateUrl: './select2.component.html',
  styleUrls: ['./select2.component.scss'],
})
export class Select2Component implements OnInit, OnChanges {

  clientEnum = ClientEnum;
  selectedValues: any[] = [];
  @Input() field: string;
  @Input() isIcon: boolean;
  @Input() filterValues: any[];
  @Input() placeholder: string;
  @Input() styleClass: string;
  @Input() defaultFilter: string[];
  @Input() matchMode = 'in';
  @Output() change = new EventEmitter<any>();

  ngOnChanges() {
    // set la valeur du multiselect input
    if (this.defaultFilter !== undefined && this.defaultFilter !== null) {
      this.selectedValues = this.defaultFilter;
    }
  }

  ngOnInit(): void {

  }

  handleChange(event): void{
    const filterObject = {
      value: event,
      field: this.field,
      matchMode: this.matchMode
    };
    this.change.emit(filterObject);
  }

}
