import { Injectable } from '@angular/core';
import { Topic } from '@app/interfaces/topic';
import { XtlReducer } from '@app/interfaces/xtl-reducer';
import {projectListSchema, topicListSchema, topicSchema, variableSchema} from '@app/model/xtl-project';
import { environment } from '@environments/environment';
import { normalize } from 'normalizr';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { NetworkService } from '../network.service';

@Injectable({
  providedIn: 'root'
})
export class TopicService implements XtlReducer<Topic> {

  constructor(private networkService: NetworkService,
              private messageService: MessageService
  ) { }
  create(data: Partial<Topic>): Observable<any | { error: string; }> {
    return this.networkService
        .post(environment.apiUrl + '/topics', data)
        .pipe(
            tap(result => {
              this.messageService.add({
                severity: 'success',
                summary: 'Rubrique ajouté',
                detail: 'La rubrique a été correctement ajoutée.',
                life: 2000
              });
            }),
            map(result => normalize(result, topicSchema)),
            catchError((err, caught) => {
              this.messageService.add({
                severity: 'error',
                summary: 'Erreur de la création de la rubrique',
                detail: 'La création de la rubrique a échouée, si le problème persiste merci de contacter un administrateur.',
                life: 10000
              });
              return [];
            })
        );
  }
  update(data: Partial<Topic>): Observable<any | { error: string; }> {
    return this.networkService
      .put(environment.apiUrl + '/topics/' + data.id, data)
      .pipe(
        tap(result => {
          this.messageService.add({
            severity: 'success',
            summary: 'Rubrique modifiée',
            detail: 'La rubrique a été correctement modifiée.',
            life: 2000
          });
        }),
        map(result => normalize(result, topicSchema)),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de modification de la rubrique',
            detail: 'La modification de la rubrique a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 10000
          });
          return [];
        })
      );
  }
  updateRanks(data: Partial<Topic>): Observable<any | { error: string; }> {
    return this.networkService
      .post(environment.apiUrl + '/topics_put' , data)
      .pipe(
        tap(result => {
          this.messageService.add({
            severity: 'success',
            summary: 'Organisation modifiée',
            detail: 'L\'organisation des rubriques a été correctement modifiée.',
            life: 2000
          });
        }),
        // map(result => normalize(result, topicSchema)),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de modification de la rubrique',
            detail: 'La modification de la rubrique a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 10000
          });
          return [];
        })
      );
  }

  fetchTopicsByVersionId(versionId: string): Observable<any> {
    return this.networkService
        .get(environment.apiUrl + '/topics?version.id%5B%5D=' + versionId)
        .pipe(
            map(result => normalize(result, topicListSchema)
            ),
            catchError((err, caught) => {
              this.messageService.add({
                severity: 'error',
                summary: 'Erreur de récupération des topics',
                detail: 'Le chargement des projets a échoué, si le problème persiste merci de contacter un administrateur.',
                life: 10000
              });
              return [];
            })
        );
  }
  remove(target: Topic): Observable<true | { error: string; }> {
    throw new Error('Method not implemented.');
  }
  fetch(data: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
}
