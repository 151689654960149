import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AuthGuardService} from '@app/service/auth/auth-guard.service';
import {AuthActionTypes} from '@app/enums/auth-action-types.enum';
import {StoreService} from '@app/service/store.service';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {

  getRole: string;

  constructor(public authGuardService: AuthGuardService,
              public storeService: StoreService) {
    storeService.state$.pipe(
        map(state => state.currentUser)
    ).subscribe(
        currentUser => {
          if (currentUser) {
            this.getRole = currentUser.role;
          }
        }
    );
  }

  @Input() display: boolean;
  @Output() hideMenu = new EventEmitter<any>();
  public userIcon = 'assets/icon/user.png';
  authAction = AuthActionTypes;
  onCloseMenuClick() {
    this.hideMenu.emit();
  }
}
