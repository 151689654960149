import {Component, Input, Output, EventEmitter} from '@angular/core';
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent{

  @Input() display: boolean = false;
  @Input() header: string = '';
  @Input() content: string = '';
  @Input() confirmBtn: boolean = true;
  @Input() cancelBtnTxt: string = 'Annuler';
  @Output() confirm = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<any>();

  onConfirmDialog() {
    this.confirm.emit(true);
  }

  onCloseDialog(){
    this.cancel.emit(true);
  }
}
