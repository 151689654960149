import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthService } from '@app/service/auth/auth.service';
import {FormValidatorService} from '@app/service/helper/form-validator.service';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-page-activate-account',
  templateUrl: './page-activate-account.component.html',
  styleUrls: ['./page-activate-account.component.scss'],
})
export class PageActivateAccountComponent implements OnInit {

  resetPasswordToken: string;
  accountEmail: string;
  setPassForm: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder,
              private activeRoute: ActivatedRoute,
              private router: Router,
              private xtlMsgService: MessageService,
              private authService: AuthService,
              public formValidator: FormValidatorService
  ) {
    activeRoute.queryParams
        .subscribe((params) => {
          this.resetPasswordToken = params.token;
          this.accountEmail = params.email;
            // Si pas de token ou email dans la requete on a un problème, le lien de init password doit avoir les deux infos
          if (!this.resetPasswordToken || !this.accountEmail) {
            this.showMessage('error', 'Informations manquantes', 'Impossible de récupérer les informations pour l\'activation du compte, avez-vous bien suivi le lien d\'initialisation du mot de passe envoyé par email ?', true);
          } else {
              // On vérifie que le token est toujours valide
            this.authService.checkResetPasswordToken({ token: this.resetPasswordToken, email: this.accountEmail}).subscribe(
                result => {
                  if (!result.success) {
                    this.showMessage('error', 'Jeton sécurité invalide', 'Le jeton de sécurité permettant l\'activation du compte n\'est pas valide ou a expiré, merci de passer par la fonction "mot de passe oublié".', true);
                  }
                },
                error => {
                  this.showMessage('error', 'Erreur vérification jeton sécurité', 'La vérification de la validité du jeton permettant l\'activation du compte a échouée si le problème persiste merci de passer par la fonction "mot de passe oublié"', true);
                });
          }
        });
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.setPassForm = this.formBuilder.group({
          pass: ['', [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern('(^(?=.*\\d)(?=.*[A-Za-z])(?=.*[^\\w\\d\\s:])([^\\s]){8,}$)')
          ]],
          confirmPass: ['', Validators.required]
        },
        {
          // Used custom form validator name
          validator: this.ComparePassword('pass', 'confirmPass')
        }
    );
  }

  get f() { return this.setPassForm.controls; }

  // custom validator -> peut être rangé dans un service ?
  ComparePassword(
      controlName: string,
      matchingControlName: string
  ) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({mustMatch: true});
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  onSubmit() {
      this.submitted = true;
    if(this.setPassForm.valid) {
        this.authService.changePassword({ token: this.resetPasswordToken, email: this.accountEmail, password: this.setPassForm.value.pass}).subscribe(
            result => {
                if (!result.success) {
                    this.showMessage('error', 'Erreur à l\'enregistrement du mot de passe', 'L\'enregistrement du mot de passe à échoué, si le problème persiste, merci de contacter un administrateur ou passez par la fonction "mot de passe oublié".');
                } else {
                    this.showMessage('success', 'Mot de passe créé', 'Votre mot de passe a bien été enregistré. Vous pouvez maintenant vous connecter à l\'application', true);
                }
            },
            error => {
                this.showMessage('error', 'Erreur requête enregistrement mot de passe', 'La requête d\'enregistrement du mot de passe a échoué, si le problème persiste, merci de contacter un administrateur ou passez par la fonction "mot de passe oublié".');
            });
    }
  }

  private showMessage(severity: string, summary: string, detail: string, redirectLogin?: boolean) {
      this.xtlMsgService.add({
          severity: severity,
          summary: summary,
          detail: detail,
          life: 5000
      });
      if (redirectLogin) {
          this.router.navigate(['/login']);
      }
  }
}
