import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ic-incoterm-truck',
  templateUrl: './ic-incoterm-truck.component.html',
  styleUrls: ['./ic-incoterm-truck.component.scss']
})
export class IcIncotermTruckComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
