import { Component, Input, OnInit } from '@angular/core';
import { ClientEnum } from '../../../enums/Client.enum';
import {EquipmentEnum} from '../../../enums/Equipment.enum';

@Component({
  selector: 'app-icon-sdccc',
  templateUrl: './icon-sdccc.component.html',
  styleUrls: ['./icon-sdccc.component.scss']
})
export class IconSdcccComponent implements OnInit {

  /* Composant générique pour l'intégration des icons de SDCCC,
  il renvoie une icon material,
  custom (kit ou pièce)
  ou une medaille selon ses input */
  clientEnum = ClientEnum;
  equipmentEnum = EquipmentEnum;
  @Input() name: string;
  @Input() type: 'material' | 'custom' | 'medal' = 'material';
  @Input() color: 'black' | 'regular' | 'disabled' | 'critique' | 'vigilance' | 'valid' | 'error' | 'default' = 'default';
  @Input() badge: 'new' | 'update' | number | null = null;
  @Input() size: 'small' | 'big' | null = null;
  @Input() tooltiptext: string;

  constructor() { }

  ngOnInit(): void {
  }

}
