import { Injectable } from '@angular/core';
import {XtlReducer} from '@app/interfaces/xtl-reducer';
import {Project} from '@app/interfaces/project';
import {Observable} from 'rxjs';
import {NetworkService} from '@app/service/network.service';
import {MessageService} from 'primeng/api';
import {environment} from '@environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {normalize} from 'normalizr';
import {projectListSchema, projectSchema} from '@app/model/xtl-project';

@Injectable({
  providedIn: 'root'
})
export class ProjectService implements XtlReducer<Project>{

  constructor(private networkService: NetworkService,
              private messageService: MessageService
  ) { }

  create(data: Partial<Project>): Observable<{ error: string } | any> {
    return this.networkService
      .post(environment.apiUrl + '/projects', data)
      .pipe(
        tap(result => {
          this.messageService.add({
            severity: 'success',
            summary: 'Projet ajouté',
            detail: 'Le projet a été correctement ajouté.',
            life: 5000
          });
        }),
        map(result => normalize(result, projectSchema)),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de création du projet',
            detail: 'La création du projet a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 10000
          });
          return [];
        })
      );

  }

  fetch(): Observable<any> {
    return this.networkService
      .get(environment.apiUrl + '/projects')
      .pipe(
        map(result => normalize(result, projectListSchema)
        ),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de récupération des projets',
            detail: 'Le chargement des projets a échoué, si le problème persiste merci de contacter un administrateur.',
            life: 10000
          });
          return [];
        })
      );
  }
  fetchByUserCompany(companyName: string): Observable<any> {
    return this.networkService
      .get(environment.apiUrl + '/projects?company.name%5B%5D=' + companyName)
      .pipe(
        map(result => normalize(result, projectListSchema)
        ),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de récupération des projets',
            detail: 'Le chargement des projets a échoué, si le problème persiste merci de contacter un administrateur.',
            life: 10000
          });
          return [];
        })
      );
  }

  remove(target: Project): Observable<true | { error: string }> {
    return undefined;
  }

  update(data: Partial<Project>): Observable<{ error: string } | any> {
    return this.networkService
      .put(environment.apiUrl + '/projects/' + data.id, data)
      .pipe(
        tap(result => {
          this.messageService.add({
            severity: 'success',
            summary: 'Projet modifiée',
            detail: 'Le projet a été correctement modifiée.',
            life: 5000
          });
        }),
        map(result => normalize(result, projectSchema)),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de modification du projet',
            detail: 'La modification du projet a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 10000
          });
          return [];
        })
      );
  }

  get(id: number): Observable<any | { error: string; }> {
    return this.networkService
        .get(environment.apiUrl + '/projects/' + id)
        .pipe(
          map(result => normalize(result, projectSchema))
        );
    }

}
