import {
  Component,
  ViewChild,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  OnDestroy,
  HostListener,
  Output,
  EventEmitter
} from '@angular/core';
import {Table} from 'primeng/table';
import {XtlTableFilters} from '../../model/xtl-tableFilters';
import {XtlLinkBtn} from '../../model/xtl-linkBtn';
import {XtlCols} from '../../model/xtl-cols';
import {ActionTypes} from '@app/enums/action-types.enum';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit, OnChanges, OnDestroy {

  @Input() data: any[];
  @Input() cols: XtlCols[];
  @Input() linkBtn: XtlLinkBtn[];
  @Input() defaultSortCols: string;
  @Input() globalFilterFields: string[];
  @Input() globalFilter: string;
  @Input() tableFilters: XtlTableFilters[];
  @Input() statuses: string[];
  // 1 = ASC, -1 = DESC
  @Input() sortOrder = 1;
  @Input() paginator = false;
  @Input() nbRows: number;
  @Input() resetPaginator = false;
  @Output() action = new EventEmitter<{ actionType: string, id: number }>();
  @Output() filteredValues = new EventEmitter<any>();

  @ViewChild('dt') table: Table;
  actionsTypes = ActionTypes;

  initialized = false;
  @Input() loading = true;

  constructor() {
  }

  ngOnInit(): void {
    this.initialized = true;
  }

  // hostlistener ecoute un event du dom (destroy non appelé sinon...)
  @HostListener('unload', ['$event'])
  ngOnDestroy(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.initialized) {
      this.filterTable();
      if (this.resetPaginator) {
        this.table.first = 0;
      }
    }
  }

  filterTable(): void {
    if (this.tableFilters !== undefined) {
      Object.keys(this.tableFilters).forEach(
        filterKey => {
          this.table.filter(this.tableFilters[filterKey].value, filterKey, this.tableFilters[filterKey].matchMode);
        });
    }
    if (this.globalFilter !== undefined) {
      this.table.filterGlobal(this.globalFilter, 'contains');
      if (this.globalFilter === '') {
        this.globalFilter = undefined;
      }
    }
  }

  onActionClick(actionType: string, id: number): void {
    this.action.emit({actionType, id});
  }
  trackByFunction = (index, item) => {
    return item.id;
  }

  getFilterredValues(event): void {
    this.filteredValues.emit(event.filteredValue);
  }
}
