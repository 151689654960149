import { Injectable } from '@angular/core';
import { XtlReducer } from '../../interfaces/xtl-reducer';
import { User } from '../../interfaces/user';
import { environment } from 'src/environments/environment';
import {map, catchError, tap, take} from 'rxjs/operators';
import { NetworkService } from '../network.service';
import { Observable } from 'rxjs';
import {MessageService} from 'primeng/api';
import {normalize} from 'normalizr';
import {userListSchema, userSchema} from '@app/model/xtl-user';

@Injectable({
  providedIn: 'root'
})
export class UserService implements XtlReducer<User>{
  constructor(
      private networkService: NetworkService,
      private messageService: MessageService
  ) { }
  create(data: Partial<User>): Observable<any | { error: string; }> {

    return this.networkService
      .post(environment.apiUrl + '/users', data)
      .pipe(
        tap(result => {
          this.messageService.add({
            severity: 'success',
            summary: 'Compte utilisateur créé',
            detail: 'Le compte utilisateur <b>' + data.firstName + ' ' + data.lastName + '</b> a été correctement créé.',
            life: 5000
          });
        }),
          map(result => normalize(result, userSchema)),
          catchError((err, caught) => {
          console.log(err); // TODO catcher le detail de l'erreur pour traiter le cas duplicate_username
          // (pour le moment ça choppe un 'Bad request' sans detail
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de création compte utilisateur',
            detail: 'La création du compte utilisateur a échoué, si le problème persiste merci de contacter un administrateur.',
            life: 10000
          });
          return [];
        })
      );
  }
  update(data: Partial<User>): Observable<any | { error: string; }> {
    return this.networkService
      .put(environment.apiUrl + '/users/' + data.id, data)
      .pipe(
        tap(result => {
          this.messageService.add({
            severity: 'success',
            summary: 'Compte utilisateur modifié',
            detail: 'le compte utilisateur <b>' + data.firstName + ' ' + data.lastName + '</b> a été correctement modifié.',
            life: 10000
          });
        }),
        map(result => normalize(result, userSchema)),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de modification compte utilisateur',
            detail: 'La modification du compte utilisateur a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 10000
          });
          return [];
        })
      );
  }

  remove(target: User): Observable<true | { error: string; }> {
    throw new Error('Method not implemented.');
  }

  fetch(): Observable<any> {
    return this.networkService
    .get(environment.apiUrl + '/users')
    .pipe(
      map(result => normalize(result, userListSchema)),
      catchError((err, caught) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erreur de récupération des comptes utilisateurs',
          detail: 'Le chargement des comptes utilisateurs a échoué, si le problème persiste merci de contacter un administrateur.',
          life: 10000
        });
        return [];
      })
    );
  }
  get(id: number): Observable<any | { error: string; }> {
    return this.networkService.get(environment.apiUrl + '/users/' + id);
  }
}
