import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { formatDate} from '@angular/common';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {

  @Input() placeholder: string;
  @Input() showIcon = false;
  @Input() styleClass: string;
  @Input() parentForm: FormGroup;
  @Input() parentFormControlName: string;
  @Input() defaultFilter: string;
  @Input() view: string;
  @Input() disabled = false;
  @Output() onSelect = new EventEmitter<any>();
  @Output() onClearClick = new EventEmitter<any>();

  formControlName: string;
  maxYearRange: number;
  minYearRange: number;
  selectedValue: Date;

  ngOnInit(): void {
    this.formControlName = this.parentFormControlName;
    // set the max year of range year dropdown to this year + 5 years
    this.maxYearRange = new Date().getFullYear() + 5;
    this.minYearRange = new Date().getFullYear();
  }

  handleSelectFullDate(value) {
    this.onSelect.emit(value);
  }
  handleSelectMonth(value) {
    this.onSelect.emit(value);
  }
}
