import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '@app/service/auth/auth.service';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import {FormValidatorService} from '@app/service/helper/form-validator.service';

@Component({
    selector: 'app-page-reset-password',
    templateUrl: './page-reset-password.component.html',
    styleUrls: ['./page-reset-password.component.scss']
})
export class PageResetPasswordComponent implements OnInit {

    emailForm: FormGroup;
    submitted = false;

    constructor(private formBuilder: FormBuilder,
                private router: Router,
                private messageService: MessageService,
                private authService: AuthService,
                private location: Location,
                public formValidator: FormValidatorService
    ) {}

    ngOnInit(): void {
        this.initForm();
    }

    initForm() {
        this.emailForm = this.formBuilder.group({
                email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+[.][a-z]{2,4}$')]]
            },
        );
    }
    get f() { return this.emailForm.controls; }

    onCancel() {
        this.location.back();
    }

    onSubmit() {
        this.submitted = true;
        if (this.emailForm.invalid) {
            return;
        }
        if (this.emailForm.valid) {
            this.authService.resetPassword({email: this.emailForm.value.email}).subscribe(
                result => {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Email réinitialisation du mot de passe envoyé',
                        detail: 'Si l\'email que vous avez renseigné est correct, un email vient d\'être envoyé à cette adresse pour la réinitialisation de votre mot de passe.',
                        life: 5000
                    });
                    this.router.navigate(['/login']);
                },
                error => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Erreur demande de réinitialisation du mot de passe',
                        detail: 'La requête de demande de réinitialisation du mot de passe a échouée, si le problème persiste, merci de contacter un administrateur.',
                        life: 5000
                    });
                });
        }
    }

}
