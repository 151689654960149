import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-bloc-global-indicator',
  templateUrl: './bloc-global-indicator.component.html',
  styleUrls: ['./bloc-global-indicator.component.scss']
})
export class BlocGlobalIndicatorComponent implements OnInit {

  @Input() globalIndicators: {nbCritique: number, nbVigilance: number, nbImpossibleRealisation: number, errorCaoDivalto: number};
  constructor() { }

  ngOnInit(): void {
  }

}
