import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-page-base',
  templateUrl: './page-base.component.html',
  styleUrls: ['./page-base.component.scss']
})
export class PageBaseComponent {

  @Input() boxShadowClass: string = 'border-box-shadow';

  constructor() {}

}
