import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageBaseComponent } from './page-base/page-base.component';
import { PageLoginComponent } from './page-login/page-login.component';
import { PageUserListComponent } from './page-user-list/page-user-list.component';
import { PageProjectDetailComponent } from './page-project-detail/page-project-detail.component';
import { PageProjectListComponent } from './page-project-list/page-project-list.component';
import { UiModule } from '../ui/ui.module';
import {SharedModule} from '../shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ServiceModule} from '../service/service.module';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {MultiSelectModule} from 'primeng/multiselect';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {CalendarModule} from 'primeng/calendar';
import {PageResetPasswordComponent} from '@app/page/page-reset-password/page-reset-password.component';
import {PageActivateAccountComponent} from '@app/page/page-activate-account/page-activate-account.component';
import {PageChangePasswordComponent} from '@app/page/page-change-password/page-change-password.component';

@NgModule({
  declarations: [
    PageBaseComponent,
    PageLoginComponent,
    PageUserListComponent,
    PageProjectDetailComponent,
    PageProjectListComponent,
    PageResetPasswordComponent,
    PageActivateAccountComponent,
    PageChangePasswordComponent
  ],
    imports: [
        CommonModule,
        UiModule,
        SharedModule,
        FormsModule,
        ServiceModule,
        ReactiveFormsModule,
        ProgressSpinnerModule,
        OverlayPanelModule,
        MultiSelectModule,
        AutoCompleteModule,
        CalendarModule
    ]
})
export class PageModule { }
