import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { NetworkService } from '../network.service';
import { environment } from 'src/environments/environment';
import { BackLog } from '@app/interfaces/backlog';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { normalize } from 'normalizr';
import { backlogListSchema, backlogSchema } from '@app/model/xtl-project';

@Injectable({
  providedIn: 'root'
})
export class BacklogService {

  constructor(
    private networkService: NetworkService,
    private messageService: MessageService
  ) { }
  create(data: Partial<BackLog>): Observable<any | { error: string; }> {

    throw new Error('Method not implemented.');
  }
  update(data: Partial<BackLog>): Observable<any | { error: string; }> {
  
    throw new Error('Method not implemented.');
  }
  
  remove(target: BackLog): Observable<true | { error: string; }> {
    throw new Error('Method not implemented.');
  }
  fetch(): Observable<any> {
    return this.networkService
    .get(environment.apiUrl + '/back_logs')
    .pipe(
      map(result => normalize(result, backlogListSchema)),
      catchError((err, caught) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erreur de récupération des comptes utilisateurs',
          detail: 'Le chargement des comptes utilisateurs a échoué, si le problème persiste merci de contacter un administrateur.',
          life: 10000
        });
        return [];
      })
    );
  }
  get(id: number): Observable<any | { error: string; }> {
    return this.networkService.get(environment.apiUrl + '/back_logs/' + id)
      .pipe(
        map(result => normalize(result, backlogSchema))
      );
  }
}
