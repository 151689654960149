import {Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';
import {PdfEncodedProviderService} from './pdf-encoded-provider.service';
import {jsPDF} from 'jspdf';
import {EquipmentStatus} from '../enums/status.enum';
import {EquipmentEnum} from '../enums/Equipment.enum';

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  constructor(
    private imageProvider: PdfEncodedProviderService,
    public datepipe: DatePipe,
  ) {
  }

  // lib js http://raw.githack.com/MrRio/jsPDF/master/docs/index.html
  // example of document https://andrekelling.de/works/jspdf-template/
  // on github https://github.com/AndreKelling/jspdf-template


}
