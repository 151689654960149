import { schema } from 'normalizr';

const tagSchema = new schema.Entity('tags');
const tagListSchema = [tagSchema];

const variableSchema = new schema.Entity('variables');
const variableListSchema = [variableSchema];

const topicDictionaryEntitySchema = new schema.Entity('topicDictionaryEntity');
const topicDictionaryEntityListSchema = [topicDictionaryEntitySchema];

const topicSchema = new schema.Entity('topics');
const topicListSchema = [topicSchema];

const entitySchema = new schema.Entity('entities', {
  topicDictionaryEntity: topicDictionaryEntitySchema,
  variables: variableListSchema
});
const entityListSchema = [entitySchema];

const userStorySchema = new schema.Entity('userStories',{
  topic: topicSchema,
  tags: tagListSchema
});
const userStoryListSchema = [userStorySchema];

const dictionarySchema = new schema.Entity('dictionary', {
  entities: entityListSchema
});
const backlogSchema = new schema.Entity('backlog', {
  userStories:  userStoryListSchema,
});
const backlogListSchema = [backlogSchema];
const dictionaryListSchema = [dictionarySchema];

const versionSchema = new schema.Entity('versions', {
});
const versionListSchema = [versionSchema];

const userSchema = new schema.Entity('users');
const userListSchema = [userSchema];

const projectSchema = new schema.Entity('projects', {
  users: userListSchema,
  versions: versionListSchema,
});
const projectListSchema = [projectSchema];

export {projectSchema};
export {projectListSchema};
export {versionSchema};
export {versionListSchema};
export {userStorySchema};
export {userStoryListSchema};
export {entitySchema};
export {entityListSchema};
export {dictionarySchema};
export {dictionaryListSchema};
export {backlogSchema};
export {backlogListSchema};
export {topicSchema};
export {topicListSchema};
export {topicDictionaryEntitySchema};
export {topicDictionaryEntityListSchema};
export {variableSchema};
export {variableListSchema};
export {tagSchema};
export {tagListSchema};


