import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import { LocalStorageService } from 'angular-web-storage';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private storage: LocalStorageService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const jwtToken = this.storage.get('jwtToken');
      const isApiUrl = request.url.startsWith(environment.apiUrl);
      // add auth header with jwt if user is logged in and request is to the api url
      if (jwtToken && isApiUrl) {
          request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + jwtToken) });
      }
      if (!request.headers.has('Content-Type')) {
          request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
      }
      return next.handle(request).pipe(
          map((event: HttpEvent<any>) => {
              if (event instanceof HttpResponse) {
                  // do nothing for now
              }
              return event;
          }),
          catchError((error: HttpErrorResponse) => {
              return throwError(error);
          })
      );
    }
}
