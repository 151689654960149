import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LoaderService} from './loader.service';
@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  constructor(private http: HttpClient,
              private loaderService: LoaderService) { }
  public get(url: string): Observable<any> {
    this.loaderService.hideLoader();
    return this.http.get(url);
  }
  public post(url: string, payload: any): Observable<any> {
    this.loaderService.hideLoader();
    return this.http.post(url, payload);
  }

  public put(url: string, payload: any): Observable<any> {
    this.loaderService.hideLoader();
    return this.http.put(url, payload);
  }
}
