import {Component, EventEmitter, Input, Output, OnInit} from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {

  @Input() placeholder: string;
  @Input() styleClass: string;
  @Input() value = '';
  @Output() input = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }
  handleInput($event): void {
    this.input.emit($event);
  }
}
