import { identifierModuleUrl } from '@angular/compiler';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActionTypes } from '@app/enums/action-types.enum';
import {TopicDictionaryEntity} from '@app/interfaces/topicdictionaryentity';
import { StoreService } from '@app/service/store.service';
import { TreeNode } from 'primeng/api';
import {TreeDragDropService} from 'primeng/api';

@Component({
  selector: 'app-structure-dico',
  templateUrl: './structure.component.html',
  providers: [TreeDragDropService],
  styleUrls: ['./structure.component.scss']
})
export class StructureDicoComponent implements OnInit {
  @Input() entitiesBytopics: any[] = [] ;
  @Input() topics: TopicDictionaryEntity[] = [] ;
  treeTopics: TreeNode[] = [];


  constructor(private store: StoreService) { }

  ngOnInit(): void {
  }

  ngOnChanges(change: SimpleChanges) {
    this.transformToTreeTopics();
  }

  onDrop(event) {
    if (
      // deplacement d'un topic par rapport a un autre topic
      (event.originalEvent.target.nodeName == 'LI' && event.dragNode.droppable && event.dropNode.droppable) ||
      // deplacement d'une us par rapport a une autre us
      (event.originalEvent.target.nodeName == 'LI' && !event.dragNode.droppable && !event.dropNode.droppable ) ||
      // deplacement d'une us dans un autre topic
      (event.originalEvent.target.nodeName != 'LI' && !event.dragNode.droppable && event.dropNode.droppable )
    ) {
      event.accept();
    }
  }

  updateRanks(){
    let topicRank = 0;
    let entityRank = 0;
    let variableRank = 0;
    let topicsToUpdate: TopicDictionaryEntity[] = [];
    let entitiesToUpdate: TopicDictionaryEntity[] = [];
    let variablesToUpdate: TopicDictionaryEntity[] = [];
    this.treeTopics.map(treeTopic => {
      let topicToUpdate: TopicDictionaryEntity[] = this.topics.filter(topic => topic.id == treeTopic.data.id);
      topicToUpdate[0].rank = topicRank;
      topicsToUpdate = [...topicsToUpdate, topicToUpdate[0]];
      topicRank++;
      treeTopic.children.map(entity => {
        let entityToUpdate: any = {id: entity.data, rank: entityRank};
        entitiesToUpdate = [...entitiesToUpdate, entityToUpdate];
        entityRank++;
        entity.children.map(variable => {
          let variableToUpdate: any = {id: variable.data, rank: variableRank};
          variablesToUpdate = [...variablesToUpdate, variableToUpdate];
          variableRank++;
        });
      });
    });
    this.store.dispatch({type: ActionTypes.UPDATE_RANKS_TOPIC_DICTIONARY_ENTITY, data: topicsToUpdate});
    this.store.dispatch({type: ActionTypes.UPDATE_RANKS_DICTIONARY_ENTITIES, data: entitiesToUpdate});
    this.store.dispatch({type: ActionTypes.UPDATE_RANKS_VARIABLES, data: variablesToUpdate});
  }

  transformToTreeTopics(){
    this.treeTopics = [];
    Object.entries(this.entitiesBytopics).map((k,v) => {
      let node: TreeNode  = {data: this.getTopicByName(k[0]), label: k[0], droppable: true, children: this.getChildren(k[1])};
      this.treeTopics = [...this.treeTopics, node];
    });
  }

  getTopicByName(name: string){
    let topic =  this.topics.filter(topic =>
      topic.name == name
    );
    return topic[0];
  }

  getChildren(entityArray): any[] {
    let child = [];
    entityArray.map(entity => {
      child = [...child, {data: entity.data.id, label: entity.data.label, rank: entity.data.rank, droppable: true, children: this.getVariablesChildren(entity.children)}];
    });
    return child;
  }

  getVariablesChildren(variableArray): any[] {
    let child = [];
    variableArray.map(variable => {
      child = [...child, { data: variable?.data?.id, label: variable?.data?.label, rank: variable?.data?.rank, droppable: false}];
    });
    return child;
  }

}
