import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ClientEnum} from '../../enums/Client.enum';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss']
})
export class MultiselectComponent implements OnInit, OnChanges {

  clientEnum = ClientEnum;
  selectedValues: any[] = [];
  @Input() field: string;
  @Input() isIcon: boolean;
  @Input() filterValues: any[];
  @Input() placeholder: string;
  @Input() styleClass: string;
  @Input() defaultFilter: string[];
  @Input() matchMode = 'in';
  @Output() change = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    // set la valeur du multiselect input
    if (this.defaultFilter !== undefined && this.defaultFilter !== null) {
      this.selectedValues = this.defaultFilter;
    }
  }

  handleChange(event): void{
    const filterObject = {
      value: event,
      field: this.field,
      matchMode: this.matchMode
    };
    this.change.emit(filterObject);
  }
}
