import { Component, OnInit } from '@angular/core';
import { UserService } from '@app/service/api/user.service';
import { Router } from '@angular/router';
import {StoreService} from '@app/service/store.service';
import {ActionTypes} from '@app/enums/action-types.enum';
import {ConfirmationService} from 'primeng/api';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';


@Component({
  selector: 'app-page-user-list',
  templateUrl: './page-user-list.component.html',
  styleUrls: ['./page-user-list.component.scss']
})
export class PageUserListComponent implements OnInit {

  userDialog: boolean;
  user: any;
  companyDialog: boolean;
  company: any;
  companies: any;
  companyListRole: any[] = [];
  submitted: boolean;
  loading: boolean;
  userTableDatas: any[] = [];
  selectedUsers: any[];
  users: any[] = [];
  userListRole = [{roleLabel: 'Extellient', roleName: 'ROLE_ADMIN'}, {roleLabel: 'Client', roleName: 'ROLE_USER'}];
  userCivility = [{civility: 'Monsieur'}, {civility: 'Madame'}];
  actifUsers = [{actifUser: true}, {actifUser: false}];
  userForm: FormGroup;
  companyForm: FormGroup;

  constructor(private userService: UserService,
              private router: Router,
              private store: StoreService,
              private confirmationService: ConfirmationService,
              private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.store.dispatch({type: ActionTypes.FETCH_USER, data: null});
    this.store.selectUsers().subscribe(
      users => {
        this.userTableDatas = [];
        users.map(
            user => {
          const formatedUser = {
            id: user.id,
            civility: user.civility,
            company: user.company,
            firstName: user.firstName,
            lastName: user.lastName,
            role: user.role,
            fullname: user.firstName + ' ' + user.lastName,
            email: user.email,
            actif: user.actif,
          };
          this.userTableDatas = [...this.userTableDatas, formatedUser];
        });
      }
    );
    this.store.selectUsers().subscribe(
        users => {
          this.users = users;
        });

    this.userForm = this.formBuilder.group({
      civility: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      company: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+[.][a-z]{2,10}$')]],
      role: ['', Validators.required],
      actif: ['', Validators.min(0)],
    });
    this.companyForm = this.formBuilder.group({
      name: ['', Validators.required]
    });

    this.store.dispatch({type: ActionTypes.FETCH_COMPANY, data: null});
    this.store.selectCompanies().subscribe(
        companies => {
          this.companies = companies;
          companies.map(
              company => {
                const formatedCompany =
                  {
                    companyLabel: company.name,
                    companyCode: company
                  };
                this.companyListRole = [...this.companyListRole, formatedCompany];
              }
          );
        }
    );
  }

  get f() { return this.userForm.controls; }
  get fCompany() { return this.companyForm.controls; }

  openNew() {
    this.user = {};
    this.submitted = false;
    this.userDialog = true;
  }

  openNewCompany() {
    this.company = {};
    this.submitted = false;
    this.companyDialog = true;
  }

  hideDialog() {
    this.userDialog = false;
    this.submitted = false;
  }

  hideCompanyDialog() {
    this.companyDialog = false;
    this.submitted = false;
  }

  editUser(user: any) {
    this.user = {...user};
    this.userDialog = true;
  }


  saveUser() {
    this.submitted = true;
    if (this.userForm.invalid) {
      return;
    }
      if (this.user.id) {
        this.userTableDatas[this.findIndexById(this.user.id)] = this.user;
        this.store.dispatch({type: ActionTypes.UPDATE_USER, data: this.user});
      }
      else {
        this.user.createdAt = new Date();
        if (!this.user.actif) {
          this.user.actif = false;
        }
        this.store.dispatch({type: ActionTypes.ADD_USER, data: this.user});
      }
      this.userDialog = false;
      this.user = {};
  }

  saveCompany() {
    this.submitted = true;
    if (this.companyForm.invalid) {
      return;
    }
    // if (this.company.id) {
    //     this.store.dispatch({type: ActionTypes.UPDATE_COMPANY, data: this.company});
    //   }
    //   else {
    this.store.dispatch({type: ActionTypes.ADD_COMPANY, data: this.company});
      // }
    this.companyDialog = false;
    this.company = {};
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.userTableDatas.length; i++) {
      if (this.userTableDatas[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  }
}
