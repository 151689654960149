import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ic-piece-fab',
  templateUrl: './ic-piece-fab.component.html',
  styleUrls: ['./ic-piece-fab.component.sass']
})
export class IcPieceFabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
