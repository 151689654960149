import {APP_INITIALIZER, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { PageModule } from './page/page.module';
import localeFr from '@angular/common/locales/fr';
// import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@environments/environment';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { UiModule } from './ui/ui.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {JwtInterceptor} from '@app/service/interceptor/jwt.interceptor';
import {ErrorInterceptor} from '@app/service/interceptor/error.interceptor';
import {registerLocaleData} from '@angular/common';
import {AppInitService} from '@app/service/app-init.service';
import { QuillModule } from 'ngx-quill'

// Translation gesture
// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http);
// }
registerLocaleData(localeFr, 'fr');
export function initializeApp(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.Init();
  };
}


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PageModule,
    UiModule,
    QuillModule.forRoot(),
    HttpClientModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppInitService], multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
