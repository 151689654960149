import {Component, OnInit} from '@angular/core';
import { UserService } from '@app/service/api/user.service';
import { Router } from '@angular/router';
import {ProjectService} from '@app/service/api/project.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import {StoreService} from '@app/service/store.service';
import {ActionTypes} from '@app/enums/action-types.enum';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';


@Component({
  selector: 'app-page-project-list',
  templateUrl: './page-project-list.component.html',
  styleUrls: ['./page-project-list.component.scss']
})
export class PageProjectListComponent implements OnInit {

  projectDialog: boolean;

  projectTableDatas: any[] = [];

  userTableDatas: any[] = [];

  project: any;

  projects: any[];

  users: any[] = [];

  user: any;

  currentUserCompanyName: string;

  searchUsers: {id: any, lastName: any}[] = [];

  selectedProjects: any[];

  submitted: boolean;

  progresses: any[];

  projectForm: FormGroup;
  getRole: string;

  companyDialog: boolean;
  company: any;
  companies: any;
  companyListRole: any[] = [];
  companyForm: FormGroup;

    constructor(private userService: UserService,
                private projectService: ProjectService,
                private router: Router,
                private messageService: MessageService,
                private store: StoreService,
                private confirmationService: ConfirmationService,
                private formBuilder: FormBuilder
                ) { }

  ngOnInit(): void {
      this.currentUserCompanyName = this.store.getCurrentUser().companyName;
      this.getRole = this.store.getCurrentUser().role;
      if (this.store.getCurrentUser().role === 'ROLE_ADMIN') {
          this.store.dispatch({type: ActionTypes.FETCH_PROJECT, data: null});
      } else {
          this.store.dispatch({type: ActionTypes.FETCH_PROJECT_BY_USER_COMPANY, data: this.currentUserCompanyName});
      }
      this.store.dispatch({type: ActionTypes.FETCH_USER, data: null});

      this.store.selectProjects().subscribe(
      projects => {
          this.projectTableDatas = [];
          projects.map(
              project => {
                  const formatedProject = {
                      id: project.id,
                      name: project.name,
                      code: project.code,
                      company: project.company,
                      progress: project.progress,
                      startDate: new Date(project.startDate),
                      endDate:  new Date(project.endDate),
                      users: project.users,
                  };
                  this.projectTableDatas = [...this.projectTableDatas, formatedProject];
              }
          );
      });

      this.store.selectUsers().subscribe(
          users => {
              this.users = users;
          });

      this.projectForm = this.formBuilder.group({
          name: ['', Validators.required],
          code: ['', Validators.required],
          company: ['', Validators.required],
          users: ['', Validators.required],
          progress: ['', Validators.required],
          startDate: ['', Validators.required],
          endDate: ['', Validators.required]
      });

      this.companyForm = this.formBuilder.group({
          name: ['', Validators.required]
      });

      this.progresses = [
      {label: 'En cours', value: 'En cours'},
      {label: 'Terminé', value: 'Terminé'},
      {label: 'A venir', value: 'A venir'}
    ];

      this.store.dispatch({type: ActionTypes.FETCH_COMPANY, data: null});
      this.store.selectCompanies().subscribe(
          companies => {
              this.companies = companies;
              companies.map(
                  company => {
                      const formatedCompany =
                          {
                              companyLabel: company.name,
                              companyCode: company
                          };
                      this.companyListRole = [...this.companyListRole, formatedCompany];
                  }
              );
          }
      );
  }

    get f() { return this.projectForm.controls; }
    get fCompany() { return this.companyForm.controls; }

    openNewCompany() {
        this.company = {};
        this.submitted = false;
        this.companyDialog = true;
    }

    filterUser(event) {
        const query = event.query;
        this.searchUsers = [];
        this.users.map(
            user => {
                if (user.role == 'ROLE_ADMIN') {
                    if (user.lastName.toLowerCase().indexOf(query.toLowerCase()) == 0 || user.firstName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                        const formatedUsers = {
                            id: user.id,
                            lastName: user.lastName,
                        };
                        this.searchUsers = [...this.searchUsers, formatedUsers];
                    }
                }
            }
        );
    }

  openNew() {
    this.project = {};
    this.submitted = false;
    this.projectDialog = true;
  }

  deleteSelectedProjects() {
      this.confirmationService.confirm({
          message: 'Are you sure you want to delete the selected project?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
              this.projectTableDatas = this.projectTableDatas.filter(val => !this.selectedProjects.includes(val));
              this.selectedProjects = null;
              this.messageService.add({severity: 'success', summary: 'Successful', detail: 'project Deleted', life: 3000});
          }
      });
  }

  editProject(project: any) {
      this.project = {...project};
      this.projectDialog = true;
  }

  deleteProject(project: any) {
      this.confirmationService.confirm({
          message: 'Are you sure you want to delete ' + project.name + '?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
              this.projectTableDatas = this.projectTableDatas.filter(val => val.id !== project.id);
              this.project = {};
              this.messageService.add({severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000});
          }
      });
  }

  hideDialog() {
      this.projectDialog = false;
      this.submitted = false;
  }

  hideCompanyDialog() {
      this.companyDialog = false;
      this.submitted = false;
  }

  saveProject() {
      this.submitted = true;
      if (this.projectForm.invalid) {
          return;
      }
      if (this.project.name.trim()) {
          if (this.project.id) {
              this.projectTableDatas[this.findIndexById(this.project.id)] = this.project;
              this.store.dispatch({type: ActionTypes.UPDATE_PROJECT, data: this.project});
          }
          else {
              this.store.dispatch({type: ActionTypes.ADD_PROJECT, data: this.project});
          }
          this.projectDialog = false;
          this.project = {};
      }
  }

    saveCompany() {
        this.submitted = true;
        if (this.companyForm.invalid) {
            return;
        }
        // if (this.company.id) {
        //     this.store.dispatch({type: ActionTypes.UPDATE_COMPANY, data: this.company});
        //   }
        //   else {
        this.store.dispatch({type: ActionTypes.ADD_COMPANY, data: this.company});
        // }
        this.companyDialog = false;
        this.company = {};
    }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.projectTableDatas.length; i++) {
        if (this.projectTableDatas[i].id === id) {
            index = i;
            break;
        }
    }

    return index;
  }

  goTo(project){
    console.log('select' + project.id);
    this.router.navigate(['/project-detail/' + project.id]);
  }


}
