import { schema } from 'normalizr';

const userSchema = new schema.Entity('users');
const userListSchema = [userSchema];

export {userSchema};
export {userListSchema};



