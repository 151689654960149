import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {StoreService} from '../store.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
      private router: Router,
      public storeService: StoreService) {
      storeService.state$.pipe(
          map(state => state.currentUser)
      ).subscribe(
          currentUser => {
            this.checkUserLogin(currentUser);
          }
      );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isGranted(route.url[0].path);
  }

  // Méthode qui permet de rediriger vers la page de login à la déconnexion par exemple
  checkUserLogin(currentUser): void {
    if (!currentUser) {
      if (this.router.url !== '/') {
        this.router.navigate(['/login']);
      }
    }
  }

  public isGranted(authAction: string): boolean {
    if (!this.storeService.getCurrentUser()) {
      this.router.navigate(['/login']);
    } else {
      if ((authAction === 'user-list' || authAction === 'project-create') && this.storeService.getCurrentUser().role === 'ROLE_USER') {
        this.router.navigate(['/project-list']);
      }
      return true;
    }
  }
  public isLoggedIn(): boolean {
    if (!this.storeService.getCurrentUser()) {
      return false;
    }
    return true;
  }
}
