import { Injectable } from '@angular/core';
import { XtlReducer } from '@app/interfaces/xtl-reducer';
import { environment } from 'src/environments/environment';
import {map, catchError, tap} from 'rxjs/operators';
import { NetworkService } from '../network.service';
import { Observable } from 'rxjs';
import {MessageService} from 'primeng/api';
import {normalize} from 'normalizr';
import {companyListSchema, companySchema} from '@app/model/xtl-company';
import {Company} from '@app/interfaces/company';

@Injectable({
  providedIn: 'root'
})
export class CompanyService implements XtlReducer<Company>{
  constructor(
      private networkService: NetworkService,
      private messageService: MessageService
  ) { }
  create(data: Partial<Company>): Observable<any | { error: string; }> {

    return this.networkService
      .post(environment.apiUrl + '/companies', data)
      .pipe(
        tap(result => {
          this.messageService.add({
            severity: 'success',
            summary: 'Entreprise créé',
            detail: 'L\'entreprise <b>' + data.name + '</b> a été correctement créé.',
            life: 5000
          });
        }),
          map(result => normalize(result, companySchema)),
          catchError((err, caught) => {
          // (pour le moment ça choppe un 'Bad request' sans detail
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de création de l\'entreprise',
            detail: 'La création de l\'entreprise a échoué, si le problème persiste merci de contacter un administrateur.',
            life: 10000
          });
          return [];
        })
      );
  }
  update(data: Partial<Company>): Observable<any | { error: string; }> {
    return this.networkService
      .put(environment.apiUrl + '/companies/' + data.id, data)
      .pipe(
        tap(result => {
          this.messageService.add({
            severity: 'success',
            summary: 'Entreprise modifiée',
            detail: 'L\'entreprise <b>' + data.name +  '</b> a été correctement modifié.',
            life: 10000
          });
        }),
        map(result => normalize(result, companySchema)),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de modification de l\'entreprise',
            detail: 'La modification de l\'entreprise a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 10000
          });
          return [];
        })
      );
  }

  remove(target: Company): Observable<true | { error: string; }> {
    throw new Error('Method not implemented.');
  }

  fetch(): Observable<any> {
    return this.networkService
    .get(environment.apiUrl + '/companies')
    .pipe(
      map(result => normalize(result, companyListSchema)),
      catchError((err, caught) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erreur de récupération des entreprises',
          detail: 'Le chargement des entreprises a échoué, si le problème persiste merci de contacter un administrateur.',
          life: 10000
        });
        return [];
      })
    );
  }
  get(id: number): Observable<any | { error: string; }> {
    return this.networkService.get(environment.apiUrl + '/companies/' + id);
  }
}
