import { Injectable } from '@angular/core';
import {EquipmentStatus} from '../../enums/status.enum';

@Injectable({
  providedIn: 'root'
})
export class ColorService {

  constructor() { }

  getColor(data): 'black' | 'regular' | 'disabled' | 'critique' | 'vigilance' | 'valid' | 'error' {
    if (data === EquipmentStatus.IMPOSSIBLE) {
      return 'black';
    } else if (data === EquipmentStatus.TODO) {
      return 'regular';
    } else if (data === EquipmentStatus.VIGILANCE) {
      return 'vigilance';
    } else if (data === EquipmentStatus.DONE) {
      return 'valid';
    } else if (data === EquipmentStatus.LATE) {
      return 'critique';
    } else if (data === EquipmentStatus.NONE) {
      return 'disabled';
    } else {
      return 'regular';
    }
  }
}
