import {Component, EventEmitter, Input, OnChanges, Output, ViewChild} from '@angular/core';
import {Checkbox} from 'primeng/checkbox';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnChanges {

  @Input() value;
  @Input() hasChildren;
  @Input() disabled = false;
  @Output() onChange = new EventEmitter<any>();
  @ViewChild('checkbox') checkbox: Checkbox;

  constructor() { }

  ngOnChanges(): void {
    if (this.checkbox !== undefined) {
      this.checkbox.writeValue(this.value);
    }
  }

  dataFieldChange(): void {
    if (!this.value && this.hasChildren) {
      this.checkbox.writeValue(false);
    }
    this.onChange.emit(!this.value);
  }
}
