export enum EquipmentEnum {
  NOTE_A = 'A',
  NOTE_B = 'B',
  NOTE_C = 'C',
  NOTE_M = 'M',
  SUB_SECTION = 'SUB_SECTION',
  COMPONENT = 'COMPONENT',
  ARTICLE = 'ARTICLE',
  PHANTOM = 'PHANTOM',
  ORDER_KIT = 'ORDER_KIT',
  SCHEMA_ELECTRIQUE = 'SCHEMA_ELECTRIQUE',
  PLAN_ENCOMBREMENT = 'PLAN_ENCOMBREMENT',
}
