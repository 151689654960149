import { Component, Input, OnInit } from '@angular/core';
import { Version } from '@app/interfaces/version';

@Component({
  selector: 'app-doc',
  templateUrl: './doc.component.html',
  styleUrls: ['./doc.component.css']
})
export class DocComponent implements OnInit {
  @Input() topics: any[] = [] ;



  constructor() { }

  ngOnInit(): void {
  }

}
