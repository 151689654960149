import { Injectable } from '@angular/core';
import { DictionaryEntity } from '@app/interfaces/dictionaryentity';
import { XtlReducer } from '@app/interfaces/xtl-reducer';
import { entitySchema } from '@app/model/xtl-project';
import { environment } from '@environments/environment';
import { normalize } from 'normalizr';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { NetworkService } from '../network.service';

@Injectable({
  providedIn: 'root'
})
export class DictionaryEntityService implements XtlReducer<DictionaryEntity>{

  constructor(private networkService: NetworkService,
    private messageService: MessageService
  ) { }
  create(data: Partial<DictionaryEntity>): Observable<any | { error: string; }> {
    return this.networkService
      .post(environment.apiUrl + '/dictionary_entities', data)
      .pipe(
          tap(result => {
              this.messageService.add({
                  severity: 'success',
                  summary: 'Entité ajoutée',
                  detail: 'L\'entité a été correctement ajoutée.',
                  life: 2000
              });
          }),
        map(result => normalize(result, entitySchema)),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de création de l\'entité',
            detail: 'La création de l\'entité a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 10000
          });
          return [];
        })
      );
  }
  update(data: Partial<DictionaryEntity>): Observable<any | { error: string; }> {
    return this.networkService
      .put(environment.apiUrl + '/dictionary_entities/' + data.id, data)
      .pipe(
        tap(result => {
          this.messageService.add({
            severity: 'success',
            summary: 'Entité modifiée',
            detail: 'L\'entité a été correctement modifiée.',
            life: 2000
          });
        }),
        map(result => normalize(result, entitySchema)),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de modification de l\'entité',
            detail: 'La modification de l\'entité a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 10000
          });
          return [];
        })
      );
  }

    updateRanks(data: Partial<DictionaryEntity>): Observable<any | { error: string; }> {
        return this.networkService
            .post(environment.apiUrl + '/dictionary_entities_put' , data)
            .pipe(
                tap(result => {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Organisation modifiée',
                        detail: 'L\'organisation des entités a été correctement modifiée.',
                        life: 2000
                    });
                }),
                // map(result => normalize(result, topicSchema)),
                catchError((err, caught) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Erreur de modification de la rubrique',
                        detail: 'La modification de l\'organisation user stories a échouée, si le problème persiste merci de contacter un administrateur.',
                        life: 10000
                    });
                    return [];
                })
            );
    }

  remove(target: DictionaryEntity): Observable<true | { error: string; }> {
    throw new Error('Method not implemented.');
  }
  fetch(data: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
}
