import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import { ActionTypes } from '@app/enums/action-types.enum';
import { Tag } from '@app/interfaces/tag';
import { Version } from '@app/interfaces/version';
import { StoreService } from '@app/service/store.service';
import { ConfirmationService } from 'primeng/api';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.css']
})
export class TagComponent implements OnInit {

  @Input() version: Version;

  tags: Tag[] = [];
  tag: Tag;
  tagDialog: boolean;
  curentVersionId: number;
  isChangesCalculated: boolean = false;
  getRole: string;
  tagForm: FormGroup;
  submitted: boolean;

  tagLevels = [{label: 'Niveau 1', value: 1}, {label: 'Niveau 2', value: 2} , {label: 'Niveau 3', value: 3}];

  constructor(private confirmationService: ConfirmationService,
              private store: StoreService,
              private formBuilder: FormBuilder
  ) { }


  ngOnInit(): void {
    console.log('INIT TAG')
    this.getRole = this.store.getCurrentUser().role;
    this.curentVersionId = this.version.id;
    this.store.dispatch({type: ActionTypes.FETCH_TAGS_BY_VERSION_ID, data: this.curentVersionId});
    this.store.selectTags().subscribe(tags => this.tags = tags);
    this.tagForm = this.formBuilder.group({
      name: ['', Validators.required],
      level: ['', Validators.required],
      color: ['', !Validators.required]
    });
  }

  get f() { return this.tagForm.controls; }

  ngOnChanges(change: SimpleChanges) {
    if (change.version){
      this.isChangesCalculated = false;
      this.curentVersionId = change.version.currentValue.backlog.id;
      this.store.dispatch({type: ActionTypes.FETCH_TAGS_BY_VERSION_ID, data: this.curentVersionId});
    }
  }

  openNew() {
    this.tag = {};
    this.tag.color = "#00ff00";
    this.tagDialog = true;
  }

  editTag(tag: Tag) {
    this.tag = {...tag};
    this.tagDialog = true;
  }

  hideDialog() {
    this.tagDialog = false;
  }

  saveTag() {
    this.submitted = true;
    if (this.tagForm.invalid) {
      return;
    }
    if (this.tag.id) {
      this.store.dispatch({type:ActionTypes.UPDATE_TAG, data:this.tag});
    }
    else {
      this.tag = {...this.tag, versionTag: {id: this.curentVersionId}};
      this.store.dispatch({type:ActionTypes.ADD_TAG, data:this.tag});
    }
    this.tagDialog = false;
    this.tag = {};
  }

}
